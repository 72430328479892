$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;
$gray-btn-color: #4b4b4b;
$gray-btn-color-hover: #313131;

#header,
#header-fixed {
    width: 100vw;
    padding-top: 10px;
    z-index: 999;
    margin: 0;
    -webkit-appearance: none;
    align-items: center;
    padding: 1.5vh 1vw;
    transition: all 0.5s;
}

.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.5vh 2vw;
    background-color: rgb(15, 15, 15, 0.2);
    border-radius: 60px;

    .navlogo {
        width: 10vw;
        transition: all 0.3s;

        &:hover {
            transform: scale(0.9);
            border-style: none;
        }
    }

    .nav-links,
    .nav-links-mobile {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        justify-content: flex-end;
        align-items: center;
        gap: 3vw;

        .btn-nav-link {
            display: flex;
            align-items: center;
            color: $gray-color-1;
            white-space: nowrap;
            padding: 3px 0px;
            font-size: 0.85rem;
            text-transform: none;
            letter-spacing: unset;
            min-width: fit-content;
            border-radius: 0;
            transition: all 0.3s ease;

            &:hover {
                border-style: solid;
                border-width: 0px 0px 3px 0px;
            }
        }

        .active {
            border-style: solid;
            border-width: 0px 0px 2.5px 0px;
        }

        .btn-sign-in {
            display: inline-block;
            position: relative;
            color: #000;
            background-color: #f1fcff;
            border-radius: 32px;
            padding: 3.5px 20px;
            font-size: 0.8rem;
            // margin-left: 1vw;
            transition: all 0.3s ease;

            &:hover {
                background-color: $main-color-hover;
            }

            &:active {
                background-color: #f1fcff;
            }
        }

        .navlogo-mobile,
        .btn-close-mobile-navbar {
            display: none;
        }
    }
}

.btn-mobile-navbar-toggler {
    display: none;
}

.nav-dropdown {
    .ant-dropdown-menu {
        display: flex;
        flex-direction: column;
        padding: 10px 12.5px;
        border-radius: 5px;

        .ant-dropdown-menu-item-group {
            .ant-dropdown-menu-item-group-title {
                font-size: 0.85rem;
                color: $gray-color-3;
                padding-left: 16px;
            }

            .ant-dropdown-menu-item-group-list {
                .ant-dropdown-menu-item {
                    padding: 5px 10px;

                    &:hover {
                        background-color: $gray-color-1;
                    }
                }
            }
        }

        .btn-menu-link {
            color: $gray-color-6;
            font-size: 0.9rem;
        }
    }
}

#header-fixed {
    .navbar {
        background-color: rgb(15, 15, 15, 0.7);
    }
}

@media (max-width: 1024px) {
    #header,
    #header-fixed {
        padding: 1.5vh 2.5vw;
    }

    .navbar {
        padding: 1.5vh 5vw;

        .navlogo {
            width: 17vw;
            height: auto;
        }

        .nav-links {
            display: none;
        }

        .nav-links-mobile {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2.5vh;
            list-style: none;
            background-color: #0f0f0f;
            padding: calc(6.5vh) 0 3.5vh 0;
            transition: all 0.5s ease-out;
            border-radius: 0 0 25px 25px;
            z-index: -1;

            .navlogo-mobile {
                display: block;
                width: 20vw;
                height: auto;
            }

            .btn-nav-link {
                font-weight: 400;
                color: rgb(255, 255, 255, 0.88);
            }

            .btn-sign-in {
                margin-left: 0;
            }

            .btn-close-mobile-navbar {
                display: block;
                border-style: none;
                background-color: unset;
            }
        }
    }

    .btn-mobile-navbar-toggler {
        display: block;
        border-style: none;
        background-color: unset;
    }
}

@media (max-width: 480px) {
    #header,
    #header-fixed {
        padding: 2.5vh 2vw;
    }

    .navbar {
        padding: 1.5vh 6.5vw;

        .navlogo {
            width: 32.5vw;
        }

        .nav-links-mobile {
            .navlogo-mobile {
                width: 35vw;
            }
        }
    }
}
