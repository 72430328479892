.footer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
    border-top: 1px solid rgb(51, 51, 51, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-content {
        width: 80vw;
        margin-bottom: 6vh;

        .footernav {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            padding: 0 2vw;
            gap: 10px;

            .footernav-title {
                font-size: 1.1rem;
                font-weight: 500;
                color: #fff;
                margin-bottom: 0;
            }

            .footernav-text {
                font-size: 0.85rem;
                color: #fff;
                transition: 0.3s;

                &:hover {
                    color: rgb(255, 255, 255, 0.7);
                }
            }

            .footernav-title-light,
            .footernav-text-light {
                color: #333;
            }

            .footernav-text-light:hover {
                color: rgb(51, 51, 51, 0.7);
            }
        }

        .ant-divider {
            border-top: 1px solid #fff;
            margin: 0;
        }

        .contact-content {
            width: 95%;
            display: flex;
            margin-top: 1vh;
            gap: 15px;

            .btn-socialMedia {
                width: 25px;
                transition: 0.5s;

                &:hover {
                    width: 20px;
                }
            }
        }
    }

    .copyright,
    color-light {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 5vw 20px 5vw;

        p {
            color: #fff;
            margin-bottom: 0;
            font-size: 0.8rem;
        }
    }

    .color-light {
        background-color: #333;
    }
}

@media (max-width: 1024px) {
    .footer {
        .footer-content {
            width: 95vw;

            .footernav {
                .footernav-title {
                    font-size: 0.8rem;
                    line-height: 1.5;
                }

                .footernav-text {
                    font-size: 0.7rem;
                }
            }

            .contact-content {
                width: fit-content;

                .btn-socialMedia {
                    width: 25px;

                    &:hover {
                        width: 15px;
                    }
                }
            }
        }

        .copyright p {
            font-size: 0.8rem;
            text-align: center;
        }
    }
}

@media (max-width: 480px) {
    .footer {
        padding-top: 3vh;

        .footer-content {
            .footernav {
                margin-top: 25px;
                align-items: center;
                padding: 0 8.5vw;
                gap: 7.5px;
            }

            .footer-contact {
                display: flex;
                align-items: center;

                .contact-content {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    margin-top: 1vh;
                }
            }
        }

        .copyright p {
            font-size: 0.7rem;
        }
    }
}
