.dialog {
  padding: 20px 30px;
  width: fit-content;
  max-width: 85%;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.dialog p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  white-space: pre-wrap;
}
.bot-dialog-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
}
.bot-dialog-container .bot-avatar {
  width: 27.5px;
  height: auto;
}
.bot-dialog-container .bot-dialog {
  background-color: #f0f0f0;
  border-radius: 15px 15px 15px 0;
  margin-left: 10px;
  overflow-x: scroll;
}
.bot-dialog-container .bot-dialog .instruction-images {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}
.bot-dialog-container .bot-dialog .instruction-images img {
  width: 12.5vw;
}
.bot-dialog-container .bot-dialog ul {
  margin-bottom: 0;
  padding-left: 1.25em;
}
.bot-dialog-container .bot-dialog ul li {
  font-size: 14px;
  font-weight: normal;
  margin: 4.5px 0;
}
.user-dialog {
  background-color: #caf1fb;
  border-radius: 15px 15px 0 15px;
  align-self: flex-end;
  margin-bottom: 20px;
}
.user-dialog .img-result {
  width: 12.5vw;
  border-radius: 10px;
}

@media (max-width: 1024px) {
}

@media (max-width: 480px) {
  .dialog {
    padding: 10px 15px;
    font-size: 14px;
  }
  .dialog p {
    font-size: 14px;
  }
  .bot-dialog-container .bot-dialog .instruction-images {
    justify-content: space-between;
  }
  .bot-dialog-container .bot-dialog .instruction-images img {
    width: 29vw;
  }
  .user-dialog .img-result {
    width: 30vw;
  }
}
