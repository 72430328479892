.modal-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: fit-content;
  background-color: #fff;
  border-radius: 30px;
  justify-content: flex-start;
  align-items: center;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  padding-bottom: 5vh;
}
.modal-title-box {
  display: flex;
  background-color: #333;
  border-radius: 30px 30px 100px 100px;
  width: 100%;
  height: 20vh;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
}
.modal-box .ant-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.patient-font {
  font-family: "Baloo 2", cursive;
}
.patient-color {
  background-color: #4bd7f3 !important;
}
.modal-btn-box {
  width: fit-content;
  padding: 0 1.5vw;
}
.modal-btn {
  position: relative;
  margin: auto;
  padding: 5px 15px;
  transition: all 0.2s ease;
  color: #fff;
  white-space: nowrap;
  margin: 0 15px;
}
.patient-btn {
  font-family: "Baloo 2", cursive;
  padding: 6px 15px;
}
.modal-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 25px;
  background: rgb(51, 51, 51, 0.2);
  width: 40px;
  height: 40px;
  transition: all 0.3s ease;
}
.modal-btn:hover:before {
  width: 100%;
  background: rgb(51, 51, 51, 1);
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15));
}
.patient-btn::before {
  background: #4bd7f366;
}
.patient-btn:hover:before {
  background: #4bd7f3;
}
.modal-btn:active {
  transform: scale(0.96);
}
.modal-btn span {
  position: relative;
  letter-spacing: 0.1em;
  vertical-align: middle;
  color: #4e4e4d;
}
.patient-btn span {
  color: #000;
}
.modal-btn:hover span {
  color: #fff;
}
.modal-btn svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #333;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.2s ease;
}
.patient-btn svg {
  stroke: #000;
}
.modal-btn:hover svg {
  transform: translateX(0);
  stroke: #fff;
}

@media (max-width: 1024px) {
  .modal-title-box {
    height: 15vh;
  }
  .modal-btn-box {
    width: 60vw;
  }
  .modal-btn {
    padding: 3px 15px;
  }
  .modal-btn span {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .modal-box {
    padding-bottom: 4vh;
  }
  .modal-title-box {
    height: 20vh;
    margin-bottom: 3vh;
  }
  .modal-btn-box {
    width: 70vw;
    height: 16vh;
  }
  .modal-btn span {
    font-size: 0.9rem;
  }
  .modal-btn {
    padding: 4px 15px;
  }
  .patient-btn {
    padding: 6px 15px;
  }
}
