$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$smile-care-main-color: #4bd7f3;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;
$gray-btn-color: #4b4b4b;
$gray-btn-color-hover: #313131;

.price-box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 35px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(102, 102, 102, 0.12) 100%);
    box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    border: 1px solid $gray-color-1;
    border-radius: 12px;
    gap: 2.5px;
    cursor: pointer;

    .plan-title {
        color: $gray-color-1;
        font-size: 1.8rem;
        margin: 0;
    }

    .price-content {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 40px;

        p {
            color: #b8b8b8;
            font-size: 0.9rem;
            line-height: 1;
            margin: 0;
        }

        h3 {
            color: $main-color;
            font-size: 1.8rem;
            line-height: 1;
            margin: 0;
        }
    }

    .annual-discount {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        background: #595959;
        backdrop-filter: blur(12px);
        border-radius: 100px;
        top: -15%;
        right: -5%;

        p,
        p span {
            font-size: 0.9rem;
            font-weight: 600;
            color: $gray-color-1;
            margin: 0;

            span {
                color: $main-color;
            }
        }
    }

    .disable-text {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(39, 39, 39, 0.85);
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            color: $warning-color;
            font-style: italic;
            font-size: 0.9rem;
            width: 60%;
            text-align: center;
            margin-bottom: 0;
        }
    }
}

@media (max-width: 1024px) {
    .price-box {
        padding: 20px 25px;
        width: 100%;

        .plan-title {
            font-size: 1.5rem;
        }

        .price-content {
            gap: 0;

            h3 {
                font-size: 1.5rem;
            }
        }
    }
}

@media (max-width: 480px) {
    .price-box {
        padding: 20px;

        .plan-title {
            font-size: 1.2rem;
        }

        .price-content {
            p {
                font-size: 0.85rem;
            }
        }

        .annual-discount {
            p,
            p span {
                font-size: 0.8rem;
            }
        }

        .disable-text {
            p {
                width: 75%;
                font-size: 16px;
            }
        }
    }
}
