.post-header {
    background: linear-gradient(90deg, #000000 -4.95%, rgba(51, 51, 51, 0.95) 105.49%);
    padding: 12vh 15vw 12vh 15vw;

    h2 {
        color: #fff;
        font-weight: bold;
        margin: 0;
    }

    .ant-divider {
        width: 30vw;
        min-width: 30vw;
        border-top: 1px #fff solid;
        margin: 20px 0;
    }

    h4 {
        color: #fff;
        font-weight: 500;
    }

    p {
        color: #fff;
        font-weight: 400;

        &:last-child {
            margin-top: -10px;
            margin-bottom: 0;
        }
    }
}

#blog-post {
    .content {
        display: flex;
        flex-direction: column;
        padding: 5vh 20vw;
        align-items: center;

        .post-img {
            width: 53.5vw;
            border-radius: 15px;
            margin-top: -15vh;
            margin-bottom: 7.5vh;
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
        }
    }
}

.allText {
    padding: 0 1vw;

    [data-testid="storyTitle"] {
        display: none;
    }

    .speechify-ignore {
        display: none;
    }

    h1 {
        font-size: 2.5rem;
        line-height: 1.5;
        color: #3c3c3c;
        margin: 30px 0;
    }

    h2 {
        font-size: 2rem;
        line-height: 1.5;
        color: #3c3c3c;
        margin: 30px 0;
    }

    h3,
    h3 strong {
        color: #3c3c3c;
        margin: 30px 0;
        font-size: 1.4rem;
        font-weight: bold;
    }

    p {
        color: #3c3c3c;
        margin-bottom: 30px;
    }

    figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2vh 0;

        &:first-child {
            display: none;
        }

        img {
            width: 35vw;
            border-radius: 10px;
        }

        figcaption {
            color: #707070;
            font-size: 0.9rem;
            margin-top: 15px;
        }
    }

    a {
        color: #3c3c3c;
        text-decoration: underline;
    }

    strong em {
        font-weight: 600;
    }

    blockquote {
        font-style: italic;
        font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
        border-left: #3c3c3c 3px solid;
        padding-left: 20px;
    }

    ul {
        margin: 0 2vw 30px 2vw;
    }

    hr,
    p:last-child {
        display: none;
    }
}

.toMedium {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: -10px;
}

.btn-toMedium {
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    position: relative;
    padding: 0.35em 1.5em;
    color: #00c8f0;
    transition: 0.3s;
    padding-right: 2.5em;
    cursor: pointer;

    &:hover {
        color: #87d0e2;
    }

    &:before,
    &:after {
        position: absolute;
        padding-top: inherit;
        padding-bottom: inherit;
        font-size: inherit;
        top: 0;
        bottom: 0;
        right: 0;
        width: 2em;
        transform-origin: 50% 60%;
    }

    &:after {
        content: "\2794";
    }

    &:hover:after {
        -webkit-animation: bounceright 0.3s alternate ease infinite;
        animation: bounceright 0.3s alternate ease infinite;
    }
}

@-webkit-keyframes bounceright {
    from {
        -webkit-transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(3px);
    }
}
@keyframes bounceright {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(3px);
    }
}

@media (max-width: 1024px) {
    .post-header {
        padding: 10vh 10vw 7.5vh 10vw;

        .ant-divider {
            width: 80vw;
            min-width: 80vw;
        }
    }

    #blog-post .content {
        padding: 3.5vh 10vw;
        align-items: center;

        .post-img {
            width: 70vw;
            margin-bottom: 5vh;
        }
    }

    .allText {
        h1 {
            font-size: 1.95rem;
            margin: 15px 0;
        }

        h2 {
            font-size: 1.7rem;
            margin: 15px 0;
        }

        h3,
        h3 strong {
            margin: 15px 0;
            font-size: 1.3rem;
        }

        figure img {
            width: 60vw;
            border-radius: 30px;
        }
    }

    .btn-toMedium {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .post-header {
        padding: 12.5vh 10vw 5vh 10vw;
    }

    #blog-post .content {
        padding: 5vh 7.5vw;
    }

    .allText {
        h1 {
            font-size: 1.4rem;
        }

        h2 {
            font-size: 1.2rem;
        }

        p,
        ul li {
            font-size: 0.95rem;
        }
    }
}
