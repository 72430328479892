.ids-popup-modal {
    .ant-modal-content {
        width: 35vw;
        background-color: rgba(#fff, 0.9);
        backdrop-filter: blur(10px);
        border-radius: 8px;

        .ant-modal-close-x {
            width: 48px;
            height: 48px;

            .anticon svg {
                font-size: 14px;
            }
        }

        .ant-modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 40px 24px;
            gap: 20px;
            opacity: 0;
            animation: imgPop 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
            animation-delay: 0.4s;
            animation-fill-mode: forwards;

            img {
                width: 40%;
                height: auto;
                object-fit: cover;
                border-radius: 4px;
                margin-bottom: 5px;
            }

            h3 {
                font-size: 22px;
                line-height: 1.5;
                font-weight: 600;
                text-align: center;
                color: #000;
                margin: 0;
            }

            p {
                font-size: 16px;
                line-height: 1.5;
                text-align: center;
                color: #000;
                margin: 0;
            }

            .ids-popup-modal-footer {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                .btn-book-appointment {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    background: url("../../assets/img/dentist/home/img_home_bg.webp") no-repeat center center;
                    background-size: cover;
                    background-position: center 22.5%;
                    padding: 8px 16px;
                    border-radius: 8px;
                    border: none;
                    transition: all 0.3s ease;
                    overflow: hidden;
                    opacity: 0;
                    animation: buttonPop 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
                    animation-delay: 0.6s;
                    animation-fill-mode: forwards;

                    span {
                        position: relative;
                        z-index: 2;
                        color: #fff;
                        font-size: 16px;
                        line-height: 1.5;
                        font-weight: 600;
                    }

                    &:hover {
                        background-position: center 26%;
                        transform: scale(1.05);

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: -100%;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(
                                to right,
                                rgba(255, 255, 255, 0) 0%,
                                rgba(255, 255, 255, 0.2) 50%,
                                rgba(255, 255, 255, 0) 100%
                            );
                            transform: skewX(-25deg);
                            z-index: 1;
                            animation: shine 0.8s;
                        }
                    }
                }

                .btn-maybe-later {
                    width: fit-content;
                    height: fit-content;
                    background-color: transparent;
                    padding: 0;
                    border-radius: 8px;
                    border: none;
                    box-shadow: none;
                    transition: all 0.3s ease;

                    span {
                        color: rgba(0, 0, 0, 0.5);
                        font-size: 14px;
                        line-height: 1.5;
                        text-decoration: underline;
                        text-underline-offset: 3px;
                        text-decoration-color: rgba(0, 0, 0, 0.3);
                        transition: all 0.3s ease;

                        &:hover {
                            color: rgba(0, 0, 0, 0.8);
                            text-decoration-color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .ids-popup-modal {
        .ant-modal-content {
            width: 52.5vw;

            .ant-modal-body {
                img {
                    width: 25%;
                }
            }
        }
    }
}

@media (max-width: 820px) {
    .ids-popup-modal {
        .ant-modal-content {
            width: 65vw;

            .ant-modal-body {
                h3 {
                    font-size: 20px;
                }

                p {
                    font-size: 14px;
                }

                .ids-popup-modal-footer {
                    .btn-book-appointment {
                        span {
                            font-size: 14px;
                        }
                    }

                    .btn-maybe-later {
                        span {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .ids-popup-modal {
        .ant-modal-content {
            width: 82.5vw;

            .ant-modal-body {
                gap: 16px;
                padding: 32px 24px;

                h3 {
                    font-size: 16px;
                }

                p {
                    font-size: 12px;
                }

                .ids-popup-modal-footer {
                    gap: 4px;

                    .btn-book-appointment {
                        padding: 4px 12px;
                        span {
                            font-size: 12px;
                        }
                    }

                    .btn-maybe-later {
                        span {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}

@keyframes shine {
    0% {
        left: -150%;
    }
    100% {
        left: 150%;
    }
}

@keyframes imgPop {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes buttonPop {
    0% {
        opacity: 0;
        transform: scale(0.8) translateY(10px);
    }
    50% {
        transform: scale(1.1) translateY(-5px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}
