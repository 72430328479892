.testimonial {
    width: 100vw;
    padding: 15vh 0;
    overflow: hidden;

    .title {
        font-weight: 600;
        font-size: 3.35rem;
        line-height: 1.4;
        color: #fff;
        text-align: center;
        margin-bottom: 12.5vh;
    }

    .testimonial-card-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
        gap: 40px;

        .testimonial-card {
            display: flex;
            flex-direction: column;
            width: 20vw;
            min-height: 40vh;
            padding: 5vh 1.5vw 2.5vh 1.5vw;
            border: 1px solid #eaeaea;
            border-radius: 30px;
            background-color: rgb(255, 255, 255, 0.15);
            box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);

            .testimonial-card-name {
                font-size: 2rem;
                line-height: 1.5;
                color: #fff;
            }

            .testimonial-card-content {
                color: #fff;
                font-size: 1.25rem;
                font-weight: 400;
                line-height: 1.5;
            }
        }

        .row-start-center {
            grid-row-start: 2;
            grid-row-end: 4;
        }

        .row-start-top {
            grid-row-start: 1;
            grid-row-end: 3;
        }

        .row-start-bottom {
            grid-row-start: 3;
            grid-row-end: 5;
        }
    }
}

@media (max-width: 1600px) {
    .testimonial {
        .title {
            font-size: 2.45rem;
        }

        .testimonial-card-container {
            .testimonial-card {
                .testimonial-card-name {
                    font-size: 1.5rem;
                }

                .testimonial-card-content {
                    font-size: 0.975rem;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .testimonial {
        padding: 12.5vh 0;

        .title {
            font-size: 1.95rem;
            margin-bottom: 7.5vh;
        }

        .testimonial-card-container {
            grid-template-columns: repeat(auto-fill, minmax(35vw, 1fr));
            gap: 35px;

            .testimonial-card {
                width: 32.5vw;
                min-height: 25vh;
                padding: 3.5vh 2.5vw 2vh 2.5vw;
            }
        }
    }
}

@media (max-width: 480px) {
    .testimonial {
        padding: 15vh 0;

        .title {
            font-size: 1.5rem;
            margin-bottom: 7.5vh;
        }

        .testimonial-card-container {
            display: flex;
            width: fit-content;
            gap: 25px;

            .testimonial-card {
                width: 80vw;
                padding: 3.5vh 7.5vw;

                .testimonial-card-name {
                    font-size: 1.2rem;
                }

                .testimonial-card-content {
                    font-size: 0.9rem;
                }
            }
        }
    }
}
