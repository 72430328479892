$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$smile-care-main-color: #4bd7f3;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;
$gray-btn-color: #4b4b4b;
$gray-btn-color-hover: #313131;

#header-patient {
    width: 100%;
    height: 80px;
    padding-top: 10px;
    z-index: 999;
    margin: 0;
    -webkit-appearance: none;
    align-items: center;
    transition: 0.5s;
}

.navbar-patient {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    padding: 0 2.5vw;
    padding-right: 2vw;

    .nav-links {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        justify-content: flex-end;
        align-items: center;
    }

    .btn-nav-link {
        display: flex;
        align-items: center;
        color: $gray-color-6;
        font-size: 1rem;
        font-family: "Baloo 2", cursive;
        white-space: nowrap;
        padding: 3px 0px;
        margin-right: 30px;
        text-transform: none;
        letter-spacing: unset;
        min-width: fit-content;
        border-radius: 0;
        transition: all 0.3s ease;

        &:hover {
            border-style: solid;
            border-width: 0px 0px 3px 0px;
        }
    }

    .btn-dentist {
        position: relative;
        padding: 5px 20px;
        border-style: none;
        transition: all 0.3s ease;
        background: none;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            border-radius: 25px;
            background: rgba(131, 228, 252, 0.4);
            width: 40px;
            height: 40px;
            transition: all 0.3s ease;
        }

        &:hover {
            &:before {
                width: 100%;
                background: $smile-care-main-color;
            }

            span {
                color: #fff;
            }

            svg {
                transform: translateX(0);
                stroke: #fff;
            }
        }

        span {
            position: relative;
            letter-spacing: 0.05em;
            vertical-align: middle;
            font-family: "Baloo 2", cursive;
            color: $gray-color-6;
        }

        svg {
            position: relative;
            top: 0;
            margin-left: 12px;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: $gray-color-6;
            stroke-width: 2;
            transform: translateX(-5px);
            transition: all 0.3s ease;
        }
    }
}

.navlogo {
    margin: 0;
    padding: 0;
    width: 170px;
    transition: all 0.3s;

    &:hover {
        transform: scale(0.9);
        border-style: none;
    }
}

#header-fixed-patient {
    height: 75px;
    padding-top: 5px;
    background: #fff;
    border-radius: 0 0 50px 50px;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    transition: 0.5s;

    .btn-nav-link {
        color: $gray-color-6;
    }
}

.mobile-navbar-toggler {
    display: none;
}

@media (max-width: 1024px) {
    #header-patient {
        height: 90px;
        width: 100vw;
        padding-top: 5px;
    }

    .navbar-patient {
        .nav-links {
            display: none;
        }

        .nav-links-mobile {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            list-style: none;
            background-color: #fff;
            width: 100%;
            left: 0;
            top: -5px;
            padding: calc(8vh + 5px) 0 5vh 0;
            transition: all 0.5s ease-out;
            border-radius: 0 0 25px 25px;
            z-index: -1;
        }

        .btn-nav-link {
            margin-right: 0;
            margin-bottom: 10px;
            color: $gray-color-6;
        }

        .btn-dentist {
            &:focus {
                margin-right: 0;
                margin-bottom: 10px;
                filter: none;
            }

            &:hover {
                &:before {
                    background: $smile-care-main-color;
                }

                span {
                    color: #fff;
                }

                svg {
                    stroke: #fff;
                }
            }
        }
    }

    #header-fixed-patient {
        height: 90px;
        border-radius: 0 0 30px 30px;
    }

    .navlogo {
        width: 22vw;
        height: auto;
    }

    .mobile-navbar-toggler {
        display: block;
        border-style: none;
        margin-right: 3vw;
        background-color: unset;
    }
}

@media (max-width: 768px) {
    #header-patient {
        height: 70px;
    }

    #header-fixed-patient {
        height: 70px;
    }
}

@media (max-width: 480px) {
    #header-patient {
        height: 60px;
    }

    #header-fixed-patient {
        height: 60px;
    }

    .navlogo {
        width: 30vw;
        height: auto;
    }
}
