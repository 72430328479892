$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$smile-care-main-color: #4bd7f3;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;
$gray-btn-color: #4b4b4b;
$gray-btn-color-hover: #313131;

#payment {
    min-height: 100vh;
    height: 100vh;
    padding: 17.5vh 5vw 5vh 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/img/dentist/bg_payment.webp");
    background-size: cover;
    background-position: bottom center;

    .product {
        width: 100%;
        display: flex;
        flex-direction: column;

        .main-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .plan-content {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                gap: 3.5vw;

                .img-dentist {
                    width: 27.5vw;
                    height: auto;
                    align-self: flex-end;
                }
            }
        }
    }

    .success-container {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 3.5vh 5vw;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.08) 0%,
            rgba(255, 255, 255, 0.06) 15.26%,
            rgba(255, 255, 255, 0.01) 100%
        );
        backdrop-filter: blur(6px);
        border-radius: 12px;

        .success-title {
            color: $gray-color-1;
        }

        .success-box {
            display: flex;
            align-items: center;
            gap: 3.5vw;

            h3,
            p {
                color: #fff;
                margin-bottom: 0;
            }

            .success-context {
                width: 22.5vw;

                .ant-result {
                    padding: 0;
                    margin-bottom: 15px;

                    .ant-result-icon {
                        margin-bottom: 0;

                        > .anticon {
                            color: $main-color;
                        }
                    }

                    .anticon svg {
                        width: 45px;
                        height: 45px;
                    }
                }
            }

            .priceDetail {
                display: flex;
                flex-direction: column;
                background-color: #000;
                border-radius: 10px;
                padding: 35px 40px;
                padding-bottom: 40px;

                .ant-divider-horizontal {
                    border-top: 1px solid rgba(228, 228, 228, 0.5);
                    margin: 15px 0;
                }

                div {
                    display: flex;
                    gap: 5vw;

                    p {
                        span {
                            color: rgba(228, 228, 228, 0.6);
                            font-size: 0.9rem;
                        }
                    }
                }
            }
        }

        .btn-openApp {
            width: fit-content;
            align-self: center;
            background-color: $main-color;
            border: unset;
            color: $gray-color-6;
            font-weight: 600;
            border-radius: 15px;
            padding: 7.5px 20px;
            margin-top: 20px;
            transition: all 0.3s;

            &:hover {
                background-color: $main-color-hover;
            }

            &:active {
                background-color: $main-color-active;
            }
        }
    }

    .payment-footer {
        margin-top: auto;
        font-size: 0.85rem;
        color: #b8b8b8;

        a {
            font-size: 0.85rem;
            color: $gray-color-1;
        }
    }

    .payment-skeleton {
        width: 35vw;
    }

    .warningMessage {
        color: $warning-color;
        margin-bottom: 30px;
        font-weight: 600;
    }
}

@media (max-width: 1024px) {
    #payment {
        height: fit-content;
        padding: 12.5vh 6.5vw 5vh 6.5vw;

        .product {
            .main-container {
                .plan-content {
                    flex-direction: column;

                    .img-dentist {
                        width: 35vw;
                        order: 2;
                        align-self: center;
                    }
                }
            }
        }

        .success-container {
            margin-top: 2.5vh;

            .success-box {
                .success-context {
                    width: 27.5vw;
                }
            }
        }

        .payment-skeleton {
            width: 85vw;
        }
    }
}

@media (max-width: 768px) {
    #payment {
        .product {
            .main-container {
                .plan-content {
                    .img-dentist {
                        width: 25vw;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    #payment {
        .product {
            .main-container {
                .plan-content {
                    .img-dentist {
                        width: 50vw;
                    }
                }
            }
        }

        .success-container {
            padding: 30px;
            margin-top: 0;

            p {
                font-size: 0.85rem;
            }

            .success-box {
                flex-direction: column;
                gap: 3vh;

                .success-context {
                    width: 70vw;
                    text-align: center;

                    .ant-result {
                        .anticon svg {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }

                .priceDetail {
                    padding: 20px 30px;

                    div {
                        gap: 12.5vw;
                    }
                }
            }
        }

        .payment-footer {
            margin-top: 5vh;
            font-size: 0.75rem;
            text-align: center;

            a {
                font-size: 0.75rem;
            }
        }
    }
}
