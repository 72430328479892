$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;

.reset {
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../../assets/img/dentist/bg_login.webp");
  background-size: cover;
  background-position: center;

  .signUp-box {
    width: 30vw;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    p {
      color: $gray-color-1;
    }
  }

  .btn-signUp {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    color: $main-color;
    transition: all 0.3s;

    &:hover {
      color: $main-color-hover;
    }

    &:active {
      color: $main-color-active;
    }
  }
}

.reset-container {
  max-width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $gray-color-1;
  background-color: $gray-color-5;
  padding: 45px 40px;
  border-radius: 8px;
  margin-top: 4vh;

  h4 {
    color: $gray-color-1;
    font-weight: bold;
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    color: $gray-color-1;
    margin-bottom: 0;
  }

  .send-text {
    color: $gray-color-2;
    margin: 30px 0;
  }

  .input-textBox {
    width: 30vw;
    border: 2px solid $gray-color-2;
    background-color: unset;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 18px;
    color: $gray-color-1;
    margin: 25px 0;

    &::placeholder {
      font-size: 18px;
      color: $gray-color-2;
    }
  }

  .btn-send {
    width: 100%;
    padding: 10px 25px;
    background-color: $main-color;
    border: none;
    color: $gray-color-5;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 5px;
    transition: all 0.3s;

    &:hover {
      background-color: $main-color-hover;
    }

    &:active {
      background-color: $main-color-active;
    }

    &:disabled {
      background-color: $gray-color-2;
      border-color: $gray-color-2;
      color: $gray-color-3;
    }

    &:disabled:hover {
      color: $gray-color-3;
    }
  }

  .incorrect {
    color: $warning-color;
    font-size: 16px;
    margin-top: -5px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1024px) {
  .reset-container {
    max-width: 60vw;
    margin-top: 3.5vh;

    .input-textBox {
      width: 50vw;
    }
  }

  .reset {
    .signUp-box {
      width: 50vw;
    }
  }
}

@media (max-width: 480px) {
  .reset-container {
    max-width: 85vw;
    padding: 40px 35px;

    .input-textBox {
      width: 70vw;
    }
  }

  .reset {
    .signUp-box {
      width: 75vw;
    }
  }
}
