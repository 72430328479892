#api .api-bg {
  background: linear-gradient(180deg, #191919 27.22%, #292929 92.57%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
#api .api-intro {
  padding: 17.5vh 20vw 0 20vw;
  min-height: 95vh;
  background-image: url(../../../assets/img/dentist/api/bg_intro.webp);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#api .api-intro h2 {
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}
#api .api-intro .ant-divider {
  border-top: 3.5px solid #83e4fc;
  margin: 35px 0;
  width: 95%;
  min-width: 95%;
}
#api .api-intro h4 {
  color: #fff;
  font-size: 1.2rem;
  width: 85%;
  text-align: center;
  font-weight: 400;
}
#api .api-bg h3 {
  color: #fff;
  text-align: center;
  width: 65%;
  margin-top: 10vh;
}
#api .ta-container {
  display: flex;
  justify-content: space-between;
  width: 85vw;
  padding: 15vh 0;
}
#api .ta-container h2 {
  font-weight: 600;
  color: #fff;
  text-align: start;
  width: fit-content;
  margin-top: 0;
  margin-bottom: 25px;
}
#api .ta-container h4 {
  color: #e4e4e4;
  font-weight: normal;
}
#api .btn-getAccess {
  border-radius: 10px;
  border: 2px solid #83e4fc;
  background-color: #83e4fc;
  font-weight: bold;
  filter: drop-shadow(0px 1px 10px rgba(228, 228, 228, 0.5));
  padding: 10px 20px;
  margin-top: 25px;
  transition: all 0.5s;
}
#api .ta-container .cta-btnBox .btn-viewDocs {
  background-color: unset;
  border-radius: 10px;
  border: 2px solid #83e4fc;
  color: #83e4fc;
  font-weight: 500;
  padding: 10px 20px;
  margin-left: 20px;
  transition: all 0.5s;
}
#api .btn-getAccess:hover,
#api .ta-container .cta-btnBox .btn-viewDocs:hover {
  transform: scale(0.9);
}
#api .ta-container .ta-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  margin-bottom: 20px;
  padding: 15px 25px;
}
#api .ta-container .ta-card .ta-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#api .ta-container .ta-card .ta-card-header svg {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}
#api .ta-container .ta-card .ta-card-header p {
  font-weight: 600;
  margin-bottom: 0;
  color: #fff;
}
#api .ta-container .ta-card .ta-card-header button {
  background-color: unset;
  border: none;
  transition: all 0.3s;
}
#api .ta-container .ta-card .ta-card-header button svg {
  width: 27.5px;
  height: 27.5px;
  margin-right: 0;
}
#api .ta-container .ta-card .ta-card-header button:hover {
  transform: scale(0.8);
}
#api .ta-container .ta-card .ta-content {
  height: 0;
  opacity: 0;
  z-index: 0;
  transition: all 0.5s ease-in-out;
}
#api .ta-container .ta-card .ta-content-show {
  height: fit-content;
  opacity: 1;
  padding: 0 5px;
  padding-top: 15px;
}
#api .ta-container .ta-card .ta-content .ant-divider {
  margin: 0;
  margin-bottom: 15px;
  border-top: 1px solid #333;
}
#api .ta-container .ta-card .ta-content ul {
  margin-bottom: 0;
  margin-top: 15px;
}
#api .ta-container .ta-card .ta-content ul li {
  font-size: 16px;
}
#api .apiDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  padding: 10vh 5vw;
}
#api .apiDetail .ant-tabs {
  align-items: center;
}
#api .apiDetail .ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  width: fit-content;
}
#api .apiDetail .ant-tabs-tab {
  padding: 15px 20px;
}
#api .apiDetail .ant-tabs-tab-btn {
  font-size: 1.1rem;
  color: #545454;
}
#api .apiDetail .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
#api .apiDetail .ant-tabs-ink-bar {
  background: #83e4fc;
}
#api .apiDetail .apiDetail-container {
  width: 95vw;
  margin-top: 5vh;
  justify-content: center;
}
#api .apiDetail .apiDetail-container .api-card {
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 10px;
  gap: 20px;
  padding: 20px;
  margin: 15px 10px;
  cursor: pointer;
}
#api .apiDetail .apiDetail-container .api-card .api-card-img {
  width: 9.5vw;
  height: auto;
  border-radius: 10px;
  filter: drop-shadow(0px 2px 3px rgba(255, 255, 255, 0.25));
  transition: all 0.3s;
}
#api .apiDetail .apiDetail-container .api-card h4 {
  font-size: 1.1rem;
  color: #fff;
}
#api .apiDetail .apiDetail-container .api-card .btn-learnMore {
  position: absolute;
  bottom: 20px;
  right: 20px;
  border: none;
  background-color: unset;
  color: #fff;
  font-size: 0.85rem;
  padding-right: 15px;
  transition: all 0.3s;
}
#api .apiDetail .apiDetail-container .api-card .btn-learnMore .arrow {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-45%);
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transition: transform 0.3s;
}
#api .apiDetail .apiDetail-container .api-card .api-card-img:hover,
#api .apiDetail .apiDetail-container .api-card .btn-learnMore:hover {
  transform: scale(0.9);
}
#api .apiDetail .apiDetail-container .api-card .btn-learnMore:hover .arrow {
  transform: translateY(-45%) rotate(45deg);
}
.api-modal {
  width: fit-content !important;
  border-radius: 10px;
}
.api-modal .ant-modal-content {
  background-color: #191919;
  border: #fff 1px solid;
  border-radius: 10px;
  width: fit-content;
}
.api-modal .ant-modal-content .ant-modal-body {
  display: flex;
  align-items: center;
  padding: 7.5vh 3.5vw;
}
.api-modal .ant-modal-content .ant-modal-body img {
  width: 20vw;
  border-radius: 10px;
  filter: drop-shadow(0px 1px 2px rgba(255, 255, 255, 0.2));
}
.api-modal .ant-modal-content .ant-modal-body .ant-divider-vertical {
  border-left: 1.5px #e4e4e4 solid;
  height: 20vw;
  margin: 0 3vw;
}
.api-modal .ant-modal-content .ant-modal-body .img-simulation {
  width: 15vw;
}
.simulation-modal .ant-modal-content .ant-modal-body .ant-divider-vertical {
  height: 27.5vw;
}
.api-modal .ant-modal-content .ant-modal-body .detail-content {
  width: 35vw;
}
.api-modal .ant-modal-content .ant-modal-body h3 {
  color: #83e4fc;
  font-weight: bold;
}
.api-modal .ant-modal-content .ant-modal-body p {
  color: #fff;
  font-weight: normal;
}
.api-modal .ant-modal-content .ant-modal-close {
  top: 3px;
  right: 5px;
}
.api-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  width: 60px;
  height: 60px;
}
.api-modal .ant-modal-content .ant-modal-close .ant-modal-close-x svg {
  fill: #fff;
}
.img-carousel {
  display: flex;
  gap: 12.5px;
}
.img-carousel button {
  border: none;
  background-color: unset;
}
.img-carousel .img-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-carousel .img-content img {
  animation: fadeIn 0.8s ease-in-out;
}
#api .api-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #292929;
  padding: 15vh 0;
}
#api .api-contact h4 {
  color: #fff;
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 400;
  text-align: center;
}
#api .api-contact .btn-contactUs {
  border: 1.5px solid #83e4fc;
  background-color: #83e4fc;
  color: #191919;
  border-radius: 10px;
  font-weight: 600;
  padding: 10px 25px;
  margin-top: 20px;
  transition: all 0.5s;
  filter: drop-shadow(0px 1px 5px rgba(228, 228, 228, 0.5));
}
#api .api-contact .btn-contactUs:hover {
  scale: 0.9;
}

@keyframes bounceY {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  #api .api-intro {
    padding: 15vh 12.5vw 0 12.5vw;
    min-height: 65vh;
  }
  #api .api-intro h2 {
    margin-top: 1.5vh;
  }
  #api .api-bg h3 {
    width: 70%;
  }
  #api .ta-container {
    width: fit-content;
    padding: 10vh 7.5vw;
    flex-direction: column;
    align-items: center;
  }
  #api .ta-container .ta-detail-container {
    margin-top: 2vh;
  }
  #api .ta-container .ta-card {
    margin-bottom: 25px;
  }
  #api .apiDetail {
    padding: 7.5vh 2.5vw;
  }
  #api .apiDetail .apiDetail-container {
    margin-top: 3.5vh;
  }
  .api-modal .ant-modal-content {
    width: fit-content;
  }
  .api-modal .ant-modal-content .ant-modal-body {
    padding: 4.5vh 5vw;
  }
  .api-modal .ant-modal-content .ant-modal-body .detail-content {
    width: 45vw;
  }
  .api-modal .ant-modal-content .ant-modal-body img {
    width: 30vw;
  }
  .api-modal .ant-modal-content .ant-modal-body .ant-divider-vertical {
    height: 30vw;
    margin: 0 4vw;
  }
  .api-modal .ant-modal-content .ant-modal-body .img-simulation {
    width: 22.5vw;
  }
  .simulation-modal .ant-modal-content .ant-modal-body .ant-divider-vertical {
    height: 45vw;
  }
  #api .api-contact {
    padding: 10vh 0;
  }
}

@media (max-width: 900px) {
  #api .api-intro h2 {
    font-size: 1.8rem;
  }
  #api .api-bg h3 {
    width: 75%;
    font-size: 1.4rem;
  }
  #api .apiDetail .apiDetail-container .api-card {
    gap: 15px;
  }
  #api .apiDetail .apiDetail-container .api-card .api-card-img {
    width: 15vw;
  }
  #api .apiDetail .apiDetail-container .api-card h4 {
    font-size: 0.95rem;
    line-height: 1.3rem;
  }
  #api .apiDetail .apiDetail-container .api-card .btn-learnMore {
    font-size: 0.8rem;
  }
  #api .api-cta h4 {
    line-height: 1.8rem;
  }
}

@media (max-width: 480px) {
  #api .api-intro {
    padding: 12.5vh 5vw 0 5vw;
    min-height: 85vh;
  }
  #api .api-intro h2 {
    font-size: 1.6rem;
    margin-top: 0;
  }
  #api .api-intro .ant-divider {
    margin: 25px 0;
  }
  #api .api-intro h4 {
    font-size: 1.1rem;
    width: 90%;
  }
  #api .ta-container .ta-detail-container {
    margin-top: 5vh;
  }
  #api .api-cta {
    margin-top: 12.5vh;
    min-height: 75vh;
    padding-top: 8vh;
    padding-left: 7.5vw;
  }
  #api .api-cta h4 {
    font-size: 1.1rem;
    width: 80vw;
  }
  #api .api-cta .cta-btnBox {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }
  #api .api-cta .cta-btnBox .btn-viewDocs {
    width: fit-content;
    margin-left: 0;
    margin-top: 20px;
  }
  #api .apiDetail .apiDetail-container {
    margin-top: 3vh;
  }
  #api .apiDetail .apiDetail-container .api-card {
    width: 87vw;
  }
  #api .apiDetail .apiDetail-container .api-card .api-card-img {
    width: 30vw;
  }
  .api-modal .ant-modal-content .ant-modal-body {
    flex-direction: column;
  }
  .api-modal .ant-modal-content .ant-modal-body img {
    width: 55vw;
  }
  .api-modal .ant-modal-content .ant-modal-body .img-simulation {
    width: 50vw;
  }
  .api-modal .ant-modal-content .ant-modal-body .ant-divider-vertical {
    border-left: none;
    border-top: 1.5px #e4e4e4 solid;
    height: unset;
    width: 75vw;
    margin: 25px 0;
  }
  .api-modal .ant-modal-content .ant-modal-body .detail-content {
    width: 70vw;
  }
  .api-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
    width: 50px;
    height: 50px;
  }
  #api .api-contact {
    padding: 10vh 10vw;
  }
  #api .api-contact h4 {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }
}
