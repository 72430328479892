$main-color: #4bd7f3;
$main-color-hover: #3ac6e3;
$main-color-active: #2ab5d2;
$gray-color-1: #e4e4e4;
$gray-color-2: #939393;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$background-color-1: #d3f1f7;
$background-color-2: #e3f5f8;
$background-color-3: #f0f0f0;
$background-color-4: #fafafa;
$text-gray-color: #a4a4a4;
$text-gray-color-3: #707070;
$text-warning-color: #ff7864;
$main-text-font: "Baloo 2", cursive;

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-box {
  width: 35%;
  display: flex;
  justify-content: space-evenly;

  .btn-openAlbum {
    width: fit-content;
    background-color: unset;
    border: 2px solid $main-color;
    color: $main-color;
    border-radius: 5px;
    padding: 3.5px 12.5px;
    font-size: 16px;
    transition: all 0.3s;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      transform: scale(0.9);
    }
  }

  .btn-takePhoto {
    width: fit-content;
    background-color: $main-color;
    border: 1.5px solid $main-color;
    color: #fff;
    border-radius: 5px;
    padding: 3.5px 12.5px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      transform: scale(0.9);
    }
  }
}

.uploadPhoto-modal {
  .ant-modal-header {
    display: flex;
    justify-content: center;
    background-color: $background-color-3;
    padding: 15px 24px;

    .ant-modal-title {
      font-size: 1.4rem;
      font-weight: 600;
      font-family: $main-text-font;
    }
  }

  .ant-modal-content {
    border-radius: 5px;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
    font-family: $main-text-font;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 20px;
  }

  .ant-modal-close-x {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .img-originalPic {
    width: 30vw;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .btn-next {
    width: fit-content;
    background-color: $main-color;
    border: 1.5px solid $main-color;
    color: #fff;
    border-radius: 5px;
    padding: 3.5px 12.5px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    &:hover {
      transform: scale(0.9);
    }
  }

  .camera-container {
    div video {
      width: 420px;
      height: calc(420px / 0.75);
    }

    p {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  .img-cameraGrid {
    position: absolute;
    width: 420px;
    z-index: 99;
  }

  .camera-footer {
    width: 100%;
    margin-top: 20px;
    align-items: center;
    margin-bottom: 5px;

    .takePhoto-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn-takePhoto {
        background-color: unset;
        border: none;
        transition: all 0.3s;

        svg {
          width: 50px;
          height: 50px;
        }

        img {
          width: 55px;
        }

        &:hover {
          transform: scale(0.9);
        }
      }

      p {
        color: #83e4fc;
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    .switch-box {
      display: flex;
      justify-content: end;

      .btn-switch {
        background-color: unset;
        border: none;
        transition: all 0.3s;

        svg {
          width: 32px;
          height: 32px;
        }

        &:hover {
          transform: scale(0.9);
        }
      }
    }

    .btn-openAlbum {
      background-color: unset;
      border: none;
      transition: all 0.3s;

      svg {
        width: 32px;
        height: 32px;
      }

      &:hover {
        transform: scale(0.9);
      }
    }

    .btn-cancel {
      width: fit-content;
      background-color: unset;
      border: 2px solid $main-color;
      color: $main-color;
      border-radius: 5px;
      padding: 3.5px 12.5px;
      font-size: 16px;
      transition: all 0.3s;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

      &:hover {
        transform: scale(0.9);
      }
    }
  }
}

.retake-modal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 35vw;

    h4 {
      text-align: center;
    }

    .btn-box {
      width: 50%;

      .btn-retake {
        background-color: unset;
        color: $main-color;
      }
    }
  }
}

@media (max-width: 1024px) {
  .btn-box {
    width: 50%;
  }

  .upload-container {
    flex-direction: column;
  }

  .uploadPhoto-modal {
    .ant-modal-header .ant-modal-title {
      font-size: 1rem;
      font-weight: 500;
    }

    .camera-container {
      div {
        width: 65vw;
      }

      div video {
        width: 65vw;
        height: calc(65vw / 0.75);
      }
    }

    .img-cameraGrid {
      width: 65vw;
    }
  }

  .retake-modal {
    .ant-modal-body {
      max-width: 60vw;

      h4 {
        font-size: 1rem;
      }

      .btn-box {
        width: 60%;
      }
    }
  }
}

@media (max-width: 480px) {
  .btn-box {
    width: 90%;
  }

  .uploadPhoto-modal {
    .ant-modal-header {
      padding: 10px 24px;
    }

    .img-originalPic {
      width: 80vw;
    }

    .camera-container {
      margin-top: 0;

      div {
        width: 85vw;
      }

      div video {
        width: 85vw;
        height: calc(85vw / 0.75);
      }
    }

    .img-cameraGrid {
      width: 85vw;
    }

    .camera-footer {
      div {
        &:first-child {
          padding-left: 2.5vw;
        }

        &:last-child {
          padding-right: 2.5vw;
        }
      }
    }
  }

  .retake-modal {
    .ant-modal-body {
      max-width: 90vw;

      h4 {
        font-size: 0.9rem;
      }

      .btn-box {
        width: 90%;
      }
    }
  }
}
