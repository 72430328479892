$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;

.login-logo {
    width: 280px;
    margin: 5vh 0;
    transition: all 0.3s;

    &:hover {
        transform: scale(0.9);
    }
}

.login {
    min-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/img/dentist/bg_login.webp");
    background-size: cover;
    background-position: center;

    .login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ffffff0d;
        border-radius: 8px;
        padding: 5vh 2.5vw;
        margin-top: 1.5vh;

        h3 {
            width: 100%;
            color: #fff;
            font-weight: bold;
            margin-bottom: 3.5vh;
            text-align: center;
        }

        .btn-google {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 30vw;
            padding: 7.5px 25px;
            background-color: #ffffff;
            border: none;
            color: $gray-color-3;
            font-weight: 600;
            border-radius: 5px;
            transition: all 0.3s;

            .icon-google {
                width: 22px;
                height: 22px;
            }

            &:hover {
                background-color: #f2f2f2;
            }

            &:active {
                background-color: #e6e6e6;
            }
        }

        .divider {
            display: flex;
            align-items: center;
            gap: 5px;
            margin: 3vh 0;

            .divider-line {
                width: 100%;
                background-color: #333;
                height: 1px;
                border-radius: 10px;
            }

            .divider-text {
                color: $gray-color-2;
                font-size: 18px;
                font-weight: bold;
                margin: 0;
            }
        }

        .input-box {
            width: 30vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 15px;

            p {
                margin-bottom: 5px;
                color: $gray-color-1;
                font-size: 16px;
            }

            .input-textBox {
                width: 100%;
                border: 2px solid $gray-color-2;
                background-color: unset;
                border-radius: 4px;
                padding: 7.5px 15px;
                font-size: 16px;
                color: $gray-color-1;

                &::placeholder {
                    font-size: 16px;
                    color: $gray-color-3;
                }

                .ant-input {
                    background-color: unset;
                    border: none;
                    color: $gray-color-1;

                    &::placeholder {
                        font-size: 16px;
                        color: $gray-color-3;
                    }
                }

                .ant-input-suffix {
                    img {
                        width: 22.5px;
                        cursor: pointer;
                    }
                }
            }
        }

        .btn-forgot {
            font-size: 16px;
            font-weight: 500;
            color: $main-color;
            transition: all 0.3s;
            margin-bottom: 15px;

            &:hover {
                color: $main-color-hover;
            }

            &:active {
                color: $main-color-active;
            }
        }

        .btn-login {
            width: 100%;
            padding: 10px 25px;
            background-color: $main-color;
            border: none;
            color: $gray-color-5;
            font-weight: bold;
            border-radius: 5px;
            margin-top: 5px;
            transition: all 0.3s;

            &:hover {
                background-color: $main-color-hover;
            }

            &:active {
                background-color: $main-color-active;
            }
        }
    }

    .signUp-text {
        color: $gray-color-1;
        margin: 4vh 0;

        .btn-signUp {
            color: $main-color;
            text-decoration: underline;
            transition: all 0.3s;

            &:hover {
                color: $main-color-hover;
            }

            &:active {
                color: $main-color-active;
            }
        }
    }
}

@media (max-width: 1024px) {
    .login-logo {
        width: 225px;
        margin: 2.5vh 0;
    }

    .login {
        .login-container {
            padding: 2.5vh 4vw;

            h3 {
                margin-bottom: 2.5vh;
            }

            .btn-google {
                width: 45vw;

                .icon-google {
                    width: 20px;
                    height: 20px;
                }
            }

            .divider {
                margin: 1.5vh 0;
            }

            .input-box {
                width: 45vw;
            }

            .btn-login {
                padding: 7.5px 25px;
            }
        }

        .signUp-text {
            margin: 2.5vh 0;
        }
    }
}

@media (max-width: 480px) {
    .login-logo {
        width: 40vw;
    }

    .login {
        .login-container {
            padding: 2.5vh 6.5vw;

            .btn-google {
                width: 75vw;
                font-size: 16px;

                .icon-google {
                    width: 18px;
                    height: 18px;
                }
            }

            .divider {
                margin: 2vh 0;

                .divider-text {
                    font-size: 16px;
                }
            }

            .input-box {
                width: 75vw;
                margin-bottom: 12.5px;

                .input-textBox {
                    .ant-input-suffix {
                        img {
                            width: 20px;
                        }
                    }
                }
            }

            .btn-forgot {
                margin-bottom: 12.5px;
            }
        }
    }
}
