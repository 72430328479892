$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$smile-care-main-color: #4bd7f3;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;
$gray-btn-color: #4b4b4b;
$gray-btn-color-hover: #313131;

.price-container {
    display: flex;
    flex-direction: column;
    padding: 3vh 2.5vw;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.08) 0%,
        rgba(255, 255, 255, 0.06) 15.26%,
        rgba(255, 255, 255, 0.01) 100%
    );
    backdrop-filter: blur(6px);
    border-radius: 12px;

    .title {
        font-size: 2.2rem;
        line-height: 1.5;
        color: $gray-color-1;
        margin-bottom: 25px;
    }

    .price-boxes {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
    }

    .btn-add-promo-code {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: rgb(228, 228, 228, 0.1);
        border-radius: 100px;
        border: none;
        color: $gray-color-1;
        font-size: 0.9rem;
        padding: 7.5px 12.5px;
        transition: all 0.3s;

        &:hover {
            background-color: rgb(228, 228, 228, 0.2);
        }

        &:active {
            background-color: rgb(228, 228, 228, 0.3);
        }
    }

    .promocode-box {
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 10px;

        .input-promo-code {
            background-color: rgb(228, 228, 228, 0.1);
            border: none;
            font-size: 0.9rem;
            padding: 7.5px 25px;
            color: $gray-color-1;

            &::placeholder {
                color: $gray-color-3;
            }

            &:disabled {
                color: $gray-color-2;
            }
        }

        .btn-apply {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $main-color;
            border-radius: 100px;
            border: none;
            font-size: 0.9rem;
            padding: 7.5px 15px;
            transition: all 0.3s;

            &:disabled {
                background-color: $gray-color-3;
                color: $gray-color-2;

                &:hover {
                    background-color: $gray-color-3;
                }

                &:active {
                    background-color: $gray-color-3;
                }
            }

            &:hover {
                background-color: $main-color-hover;
            }

            &:active {
                background-color: $main-color-active;
            }
        }

        .btn-cancel {
            background-color: unset;
            border: none;

            svg {
                width: 25px;
                height: 25px;
            }
        }
    }

    .price-divider {
        border-top: 1px solid rgb(228, 228, 228, 0.1);
        margin: 20px 0;
    }

    .guarantee-box {
        display: flex;
        flex-direction: column;

        .text-guarantee {
            font-size: 0.9rem;
            color: $gray-color-1;
            font-weight: bold;
            margin: 0;
        }

        .text-learn-more,
        .text-learn-more a {
            font-size: 0.9rem;
            color: #b8b8b8;
            margin: 0;

            a {
                text-decoration: underline;
            }
        }
    }

    .btn-submit {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $main-color;
        border-radius: 100px;
        border: none;
        color: $gray-color-6;
        padding: 7.5px 25px;
        margin-top: 5vh;
        transition: all 0.3s;

        &:hover {
            background-color: $main-color-hover;
        }

        &:active {
            background-color: $main-color-active;
        }
    }
}

@media (max-width: 1024px) {
    .price-container {
        padding: 2vh 3.5vw;

        .title {
            font-size: 1.8rem;
        }
    }
}

@media (max-width: 480px) {
    .price-container {
        .title {
            font-size: 1.5rem;
        }

        .price-boxes {
            flex-direction: column;
            justify-content: center;
            gap: 5vh;
            margin-bottom: 30px;
        }

        .btn-add-promo-code {
            font-size: 0.8rem;
        }

        .promocode-box {
            .input-promo-code,
            .btn-apply {
                font-size: 0.8rem;
            }
        }

        .price-divider {
            margin: 25px 0;
        }

        .guarantee-box {
            display: flex;
            flex-direction: column;

            .text-guarantee {
                font-size: 0.9rem;
                color: $gray-color-1;
                font-weight: bold;
                margin: 0;
            }

            .text-learn-more,
            .text-learn-more a {
                font-size: 0.8rem;
            }
        }
    }
}
