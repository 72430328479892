$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;

.change-password {
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../../assets/img/dentist/bg_login.webp");
  background-size: cover;
  background-position: center;

  .change-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    margin-top: 5vh;
    background-color: $gray-color-5;
    padding: 45px 40px;

    h3,
    p {
      color: $gray-color-1;
    }

    .input-textBox {
      width: 30vw;
      border: 2px solid $gray-color-2;
      background-color: unset;
      border-radius: 4px;
      padding: 10px 15px;
      font-size: 18px;
      color: $gray-color-1;
      margin-bottom: 25px;

      .ant-input {
        background-color: unset;
        border: none;
        color: $gray-color-1;

        &::placeholder {
          font-size: 18px;
          color: $gray-color-2;
        }
      }

      .ant-input-suffix {
        img {
          width: 22.5px;
          cursor: pointer;
        }
      }
    }
  }

  .btn-reset,
  .btn-loginAgain {
    width: 100%;
    padding: 10px 25px;
    background-color: $main-color;
    border: none;
    color: $gray-color-5;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 5px;
    transition: all 0.3s;

    &:hover {
      background-color: $main-color-hover;
    }

    &:active {
      background-color: $main-color-active;
    }

    &:disabled {
      background-color: $gray-color-2;
      border-color: $gray-color-2;
      color: $gray-color-3;
    }

    &:disabled:hover {
      color: $gray-color-3;
    }
  }

  .btn-toDashboard {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    color: $main-color;
    margin-top: 30px;
    transition: all 0.3s;

    &:hover {
      color: $main-color-hover;
    }

    &:active {
      color: $main-color-active;
    }
  }
}

@media (max-width: 1024px) {
  .change-password {
    .change-container {
      margin-top: 3vh;

      .input-textBox {
        width: 50vw;
      }
    }
  }
}

@media (max-width: 480px) {
  .change-password {
    .change-container {
      padding: 40px 35px;
      margin-top: 5vh;

      .input-textBox {
        width: 65vw;
      }
    }

    .btn-reset,
    .change-false,
    .btn-loginAgain {
      font-size: 0.95rem;
    }
  }
}
