$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;

.emailVerification {
    min-height: 100vh;
    padding: 5vh 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/img/dentist/bg_login.webp");
    background-size: cover;
    background-position: center;

    .emailVerification-container {
        width: 45vw;
        padding: 6vh 3.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: $gray-color-5;
        border-radius: 8px;
        margin-top: 5vh;

        .title {
            color: $gray-color-1;
            font-weight: bold;
        }

        .text {
            color: $gray-color-2;
            font-weight: normal;
            margin: 20px 0 30px 0;

            span {
                color: $gray-color-1;
                font-weight: bold;
            }
        }

        .btn-emailVerification {
            width: 100%;
            padding: 10px 25px;
            background-color: $main-color;
            border: none;
            color: $gray-color-5;
            font-size: 1.1rem;
            font-weight: bold;
            border-radius: 5px;
            margin-top: 5px;
            transition: all 0.3s;

            &:hover {
                background-color: $main-color-hover;
            }

            &:active {
                background-color: $main-color-active;
            }
        }
    }

    .btn-isEmailVerified,
    .btn-signInWithAnotherAccount {
        border: none;
        background-color: transparent;
        color: $gray-color-1;
        text-decoration: underline;
        font-size: 0.9rem;
        margin-top: 20px;
        transition: all 0.3s;

        &:hover {
            color: $gray-color-2;
        }

        &:active {
            color: $gray-color-3;
        }
    }

    .btn-signInWithAnotherAccount {
        margin: 3.5vh 0;
        color: $main-color;

        &:hover {
            color: $main-color-hover;
        }

        &:active {
            color: $main-color-active;
        }
    }
}

@media (max-width: 1024px) {
    .emailVerification {
        .emailVerification-container {
            width: 75vw;
            padding: 5vh 5vw;
            margin-top: 2.5vh;
        }

        .btn-signInWithAnotherAccount {
            margin: 2.5vh 0;
        }
    }
}

@media (max-width: 480px) {
    .emailVerification {
        .emailVerification-container {
            width: 85vw;
            padding: 5vh 7.5vw;

            .btn-emailVerification {
                padding: 7.5px 25px;
                font-size: 1rem;
            }

            .btn-isEmailVerified {
                font-size: 0.85rem;
            }
        }
    }
}
