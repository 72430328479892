$main-color: #4bd7f3;
$main-color-hover: #3ac6e3;
$main-color-active: #2ab5d2;
$gray-color-1: #e4e4e4;
$gray-color-2: #939393;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$background-color-1: #d3f1f7;
$background-color-2: #e3f5f8;
$background-color-3: #f0f0f0;
$background-color-4: #fafafa;
$text-gray-color: #a4a4a4;
$text-gray-color-3: #707070;
$text-warning-color: #ff7864;
$main-text-font: "Baloo 2", cursive;

#chat {
  border-radius: 20px;
  background-color: $background-color-1;
  padding: 15px;

  .chat-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    h4 {
      margin-bottom: 0;
    }
  }

  .chat-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;

    .content {
      width: 85vw;
      padding: 1vh 3.5vw;
      height: 70vh;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      scroll-behavior: smooth;
      margin-bottom: 2.5vh;

      .btn-simulation-result {
        width: fit-content;
        border: none;
        border-radius: 5px;
        background-color: $main-color;
        padding: 5.5px 17.5px;
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        color: #fff;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        margin-top: -25px;
        align-self: center;
        z-index: 100;
      }
    }

    .examples-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;
      align-self: center;
      margin-top: 5vh;

      p {
        color: $text-gray-color;
        font-size: 14px;
        line-height: 1.1rem;
      }

      .example-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 15px;

        button {
          width: fit-content;
          display: flex;
          border: none;
          background-color: $background-color-2;
          padding: 15px 30px;
          text-align: center;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          font-family: "Lato", sans-serif;
          font-size: 14px;
          white-space: nowrap;
          filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
        }

        .btn-takePic {
          display: flex;
          background-color: $main-color;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 600;
          margin: 7.5px 0;

          img {
            width: 25px;
            margin-right: 10px;
          }
        }
      }
    }

    .analysis-container {
      display: flex;
      width: 100%;
      overflow-x: scroll;

      .analysis-content-box {
        width: fit-content;
        background-color: $background-color-3;
        border-radius: 3px;
        margin: 0 5px;
        margin-bottom: 20px;

        .ant-card-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px 20px;
        }

        .analysis-imgs {
          position: relative;
          display: flex;
          justify-content: center;
          width: 20vw;

          img {
            width: 90%;
            border-radius: 3px;
            margin-bottom: 10px;
          }

          .img-result {
            position: absolute;
          }
        }

        p {
          font-size: 0.9rem;
          margin-bottom: 0;
        }
      }
    }

    .analyzing-hint {
      display: flex;

      img {
        width: 30px;
        margin-left: 5px;
      }
    }

    .simulation-container {
      display: flex;
      justify-content: space-between;

      .img-box {
        position: relative;
        width: 49%;
        display: flex;
        justify-content: center;

        img {
          border-radius: 2px;
          width: 20vw;
        }

        p {
          position: absolute;
          bottom: 0;
          color: #fff;
          font-family: "Baloo 2", cursive;
          font-weight: 500;
          text-shadow: 0.5px 0.5px 2px #00000066;
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $background-color-3;
      padding: 1.5vh 2vw;

      .input {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .btn-uploadPhoto,
        .btn-makeAppointment {
          svg {
            width: 27.5px;
            height: 27.5px;
          }
        }

        .text-input-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          background-color: $background-color-4;
          border-radius: 5px;
          padding: 10px 20px;

          input {
            background-color: $background-color-4;
            border: none;
            width: 95%;
            font-size: 0.9rem;
            padding: 3px 5px;

            &::placeholder {
              color: $text-gray-color;
              font-size: 0.9rem;
            }
          }

          button {
            width: fit-content;
            border: none;
            background-color: unset;
            height: 30px;
            cursor: pointer;

            svg {
              width: 30px;
              height: 30px;
            }
          }
        }
      }

      .analyzing-hint {
        display: flex;
        align-items: center;
        margin-bottom: 17.5px;
        animation: fadeIn 1s ease-in-out infinite alternate;

        p {
          color: $gray-color-3;
          font-weight: 600;
          margin-right: 5px;
        }

        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

.btn-get-report {
  width: fit-content;
  border: none;
  border-radius: 5px;
  background-color: $main-color;
  padding: 5.5px 17.5px;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  color: #fff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: 3vh;
  transition: all 0.3s;

  &:hover {
    background-color: $main-color-hover;
  }

  &:active {
    background-color: $main-color-active;
  }
}

.disclaimer-modal,
.getReport-modal,
.makeAppointment-modal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3.5vh 3vw;
    font-family: $main-text-font;
    max-width: 45vw;
  }
}

.disclaimer-modal,
.getReport-modal,
.consultation-modal {
  .ant-modal-content {
    border-radius: 5px;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
  }
}

.ant-modal-mask {
  z-index: 2000;
}

.ant-modal-wrap {
  z-index: 2001;
}

.disclaimer-modal,
.getReport-modal {
  .ant-modal-close {
    right: 5px;

    .ant-modal-close-x svg {
      fill: #333;
    }
  }
}

.warning-text {
  margin-top: 20px;
  color: $text-warning-color;
}

.disclaimer-modal {
  .ant-modal-header {
    display: flex;
    justify-content: center;
    background-color: $background-color-3;

    .ant-modal-title {
      font-size: 1.4rem;
      font-weight: 600;
      font-family: $main-text-font;
    }
  }

  .ant-modal-body {
    .text-box {
      display: flex;
      margin: 10px 0;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-top: 3.5px;
      }

      p {
        font-weight: 400;
      }
    }
  }

  .agree-checkbox {
    margin-top: 15px;

    span {
      font-weight: 400;
      font-size: 0.95rem;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $main-color;
      border-color: $main-color;
    }

    a {
      text-decoration: underline;
      font-weight: bold;
      color: #000;
      font-size: 0.95rem;
    }
  }

  .btn-continue {
    display: flex;
    align-self: flex-end;
    border: none;
    background-color: $main-color;
    color: #fff;
    transition: all 0.3s;
    padding: 7.5px 15px;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 0.95rem;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: $main-color-hover;
    }

    &:active {
      background-color: $main-color-active;
    }
  }
}

.getReport-modal {
  .ant-modal-body {
    align-items: center;
    padding: 5vh 3vw;

    h3 {
      margin-bottom: 30px;
    }

    .ant-input {
      font-size: 1rem;
      border-radius: 5px;
      margin-top: 2.5px;
      width: 22.5vw;
    }

    .getLatestNews-checkbox {
      margin: 25px 0;

      span {
        font-weight: 400;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $main-color;
        border-color: $main-color;
      }
    }

    .btn-send-report {
      background-color: $main-color;
      color: #fff;
      border: none;
      font-size: 1.1rem;
      padding: 7.5px 20px;
      border-radius: 5px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      transition: all 0.3s;

      &:hover {
        background-color: $main-color-hover;
      }

      &:active {
        background-color: $main-color-active;
      }
    }

    .btn-continue {
      width: fit-content;
      background-color: $main-color;
      color: #fff;
      border: none;
      font-size: 1.1rem;
      padding: 7.5px 20px;
      border-radius: 5px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      transition: all 0.3s;
      margin: 0 15px;
      margin-top: 10px;

      &:hover {
        background-color: $main-color-hover;
      }

      &:active {
        background-color: $main-color-active;
      }
    }

    .btn-cancel {
      background-color: unset;
      border: 1px solid $main-color;
      color: $main-color;

      &:hover {
        background-color: unset;
        border-color: $main-color-hover;
        color: $main-color-hover;
      }

      &:active {
        background-color: unset;
        border-color: $main-color-active;
        color: $main-color-active;
      }
    }

    .success-text {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .warning-text {
    margin: 0;
  }
}

.makeAppointment-modal {
  .ant-modal-body {
    .ant-checkbox-wrapper {
      margin-top: 10px;
      color: $text-gray-color-3;

      span a {
        color: $text-gray-color-3;
      }
    }

    .agree-checkbox {
      margin-left: 0;
      width: 30vw;

      span a {
        text-decoration: underline;
        font-weight: bold;
        color: #000;
        font-size: 0.95rem;
      }
    }

    h3 {
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      width: 30vw;
      font-weight: 400;
      color: $gray-color-2;
      margin-bottom: 10px;
    }

    .btn-continue {
      width: fit-content;
      background-color: $main-color;
      color: #fff;
      border: none;
      font-size: 1.1rem;
      border-radius: 5px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      transition: all 0.3s;
      margin-top: 10px;
      margin: 0;
      padding: 7.5px 35px;

      &:hover {
        background-color: $main-color-hover;
      }

      &:active {
        background-color: $main-color-active;
      }
    }

    .btn-cancel {
      background-color: unset;
      border: 1px solid $main-color;
      color: $main-color;

      &:hover {
        background-color: unset;
        border-color: $main-color-hover;
        color: $main-color-hover;
      }

      &:active {
        background-color: unset;
        border-color: $main-color-active;
        color: $main-color-active;
      }
    }

    .ant-input {
      width: 30vw;
      border-radius: 10px;
      padding: 15px;
      font-size: 18px;
      margin: 10px 0;
      border-color: $text-gray-color;
      color: $text-gray-color-3;
    }

    .footer-box {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }

    .warning-text {
      margin-top: 20px;
      color: $text-warning-color;
    }

    .successful-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        margin: 0;
      }

      p {
        text-align: center;
        margin: 20px 0;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $main-color;
    border-color: $main-color;
  }

  .country-box,
  .region-box {
    width: 30vw;
    border-radius: 10px;
    padding: 15px;
    font-size: 18px;
    margin: 10px 0;
    border-color: $text-gray-color;
    color: $text-gray-color-3;
  }
}

.consultation-modal {
  .ant-modal-body {
    max-width: 47.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.5vh 3vw;
    font-family: $main-text-font;

    .btn-continue {
      width: fit-content;
      background-color: $main-color;
      color: #fff;
      border: none;
      font-size: 1.1rem;
      padding: 7.5px 20px;
      border-radius: 5px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      transition: all 0.3s;
      margin: 0 15px;
      margin-top: 10px;

      &:hover {
        background-color: $main-color-hover;
      }

      &:active {
        background-color: $main-color-active;
      }
    }

    .btn-cancel {
      background-color: unset;
      border: 1px solid $main-color;
      color: $main-color;

      &:hover {
        background-color: unset;
        border-color: $main-color-hover;
        color: $main-color-hover;
      }

      &:active {
        background-color: unset;
        border-color: $main-color-active;
        color: $main-color-active;
      }
    }

    h3 {
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
    }

    p {
      text-align: center;
      color: $gray-color-3;
    }

    .ant-input {
      width: 30vw;
      border-radius: 10px;
      padding: 15px;
      font-size: 18px;
      margin: 10px 0;
      border-color: $text-gray-color;
      color: $text-gray-color-3;
    }

    .warning-text {
      margin-top: 20px;
      color: $text-warning-color;
    }
  }
}

.largeImg-modal {
  .ant-modal-body {
    display: flex;
    position: relative;
    padding: 10px;

    img {
      height: 60vh;
      width: auto;
    }
  }
}

.generating-hint {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 1.5s steps(40) infinite, blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-right-color: transparent;
  }
  50% {
    border-right-color: black;
  }
}

@media (max-width: 1024px) {
  #smile-care h2 {
    text-align: center;
  }

  .chat-container {
    .examples-container {
      width: 85%;

      .example-options button {
        padding: 15px 25px;
      }
    }
  }

  .disclaimer-modal,
  .getReport-modal,
  .makeAppointment-modal,
  .consultation-modal {
    .ant-modal-body {
      max-width: 90vw;
    }
  }

  .disclaimer-modal,
  .getReport-modal,
  .makeAppointment-modal {
    .ant-modal-body {
      padding: 5vh 7.5vw;
    }
  }

  .getReport-modal,
  .consultation-modal {
    .ant-modal-body .ant-input {
      width: 50vw;
    }
  }

  .makeAppointment-modal {
    .ant-modal-body {
      p,
      .ant-input,
      .agree-checkbox {
        width: 50vw;
      }
    }

    .country-box,
    .region-box {
      width: 50vw;
    }
  }

  .largeImg-modal {
    .ant-modal-body {
      img {
        width: 75vw;
        height: auto;
      }
    }
  }
}

@media (max-width: 480px) {
  #chat {
    width: 95vw;
    padding: 10px 15px;

    .chat-header {
      margin-bottom: 7.5px;

      h4 {
        font-size: 0.95rem;
      }
    }

    .chat-container {
      .content {
        width: 100%;
        height: 80vh;

        .btn-simulation-result {
          font-size: 0.85rem;
        }
      }

      .examples-container {
        margin-top: 7.5vh;

        p {
          line-height: 1rem;
        }

        .example-options {
          button {
            padding: 7.5px 17.5px;
            font-size: 12px;
          }

          .btn-takePic {
            font-size: 14px;
          }
        }
      }

      .analysis-container {
        .analysis-content-box {
          .ant-card-body {
            padding: 10px;
          }

          .analysis-imgs {
            width: 67.5vw;
          }
        }
      }

      .simulation-container {
        .img-box img {
          width: 100%;
        }
      }

      .footer {
        .input {
          .btn-uploadPhoto,
          .btn-makeAppointment {
            svg {
              width: 22.5px;
              height: 22.5px;
            }
          }

          .text-input-container {
            padding: 3px 5px;

            input {
              font-size: 12px;

              &::placeholder {
                font-size: 12px;
              }
            }

            button svg {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }

  .btn-get-report {
    font-size: 0.85rem;
  }

  .disclaimer-modal {
    .ant-modal-header .ant-modal-title {
      font-size: 1.1rem;
      font-weight: 500;
    }

    .ant-modal-body {
      padding: 3.5vh 5vw;

      .text-box {
        margin: 0;

        img {
          width: 15px;
          height: 15px;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }

    .agree-checkbox {
      margin-top: 5px;

      span {
        font-size: 0.9rem;

        a {
          font-size: 0.9rem;
        }
      }
    }
  }

  .makeAppointment-modal {
    .ant-modal-body {
      .agree-checkbox span a {
        font-size: 0.9rem;
      }

      .btn-continue {
        font-size: 1rem;
      }

      p,
      .ant-input,
      .agree-checkbox {
        width: 75vw;
      }

      .ant-input {
        font-size: 0.95rem;
      }
    }

    .country-box,
    .region-box {
      width: 75vw;
      font-size: 0.95rem;
    }
  }

  .getReport-modal {
    .ant-modal-body {
      h3 {
        font-size: 1.1rem;
        text-align: center;
      }

      h4 {
        font-size: 0.95rem;
        text-align: center;
      }

      .ant-input {
        width: 75vw;
        font-size: 0.95rem;
      }

      .getLatestNews-checkbox span {
        font-size: 0.9rem;
      }

      .btn-continue {
        font-size: 1rem;
      }
    }
  }

  .consultation-modal .ant-modal-body .ant-input {
    width: 75vw;
  }

  .largeImg-modal .ant-modal-body img {
    width: 85vw;
    height: auto;
  }
}
