@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~antd/dist/antd.css";

*,
*::before,
*::after {
    box-sizing: border-box;
}
* {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 18px;
    -webkit-appearance: none !important;
}
* #patientPage {
    font-family: "Baloo 2", cursive;
}

body {
    width: 100vw;
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
}
.ant-typography {
    font-family: inherit;
}
img {
    max-width: 100%;
    height: auto;
}
a {
    text-decoration: none;
}
h1 {
    font-size: 3.375rem;
    color: #3e3d3c;
    line-height: 4.175rem;
}
h2 {
    font-size: 2.25rem;
    line-height: 2.95rem;
    color: #3e3d3c;
}
h3 {
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: #3e3d3c;
}
h4 {
    font-size: 1.15rem;
    line-height: 1.85rem;
    color: #3e3d3c;
}
p {
    color: #3e3d3c;
}

.CookieConsent {
    align-items: center !important;
    padding: 1.5vh 5.5vw 1.5vh 2.5vw;
}

@media (max-width: 768px) {
    h1 {
        font-size: 2.575rem;
        line-height: 3.275rem;
    }
    h2 {
        font-size: 1.65rem;
        line-height: 2.25rem;
    }
    h3 {
        font-size: 1.4rem;
        line-height: 2rem;
    }
    h4 {
        font-size: 1.1rem;
        line-height: 1.7rem;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 1.775rem;
        line-height: 2.475rem;
    }
    h2 {
        font-size: 1.55rem;
        line-height: 2.15rem;
    }
    h3 {
        font-size: 1.075rem;
        line-height: 1.675rem;
    }
    h4 {
        font-size: 1rem;
        line-height: 1.6rem;
    }
    p {
        font-size: 0.95rem;
        line-height: 1.4rem;
    }
    .CookieConsent {
        padding: 1vh 1vw;
    }
    .CookieConsent div {
        font-size: 0.9rem;
    }
}
