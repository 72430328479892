.team {
    display: flex;
    flex-direction: column;
    padding: 10vh 8.5vw;
    gap: 2.5vh;

    h4 {
        font-size: 1.2rem;
        font-weight: 600;
        color: rgb(255, 255, 255, 0.88);
    }

    .team-background {
        display: flex;
        justify-content: space-between;

        img {
            height: 5.5vh;
            width: auto;
        }
    }
}

@media (max-width: 1024px) {
    .team {
        padding: 7.5vh 8.5vw;

        .team-background {
            img {
                height: 3vh;
                width: auto;
            }
        }
    }
}

@media (max-width: 480px) {
    .team {
        align-items: center;
        padding: 5vh 8.5vw;

        h4 {
            font-size: 1.1rem;
        }

        .team-background {
            width: 100%;
            display: grid;
            grid-template-columns: auto auto;
            justify-content: space-between;
            padding: 0 2.5vw;
            gap: 2.5vh;

            img {
                height: 3.5vh;
                width: auto;
            }
        }
    }
}
