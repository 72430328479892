.benefits {
    width: 100vw;
    padding: 20vh 4.5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url("../../../../assets/img/dentist/home/img_benefits_bg.webp");
    gap: 2.5vw;

    .img-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .whole-benefits-content {
        display: flex;
        flex-direction: column;
        gap: 80px;

        .benefit-box {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1vw;

            img {
                width: 4.5vw;
                height: auto;
            }

            .benefit-text-container {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .benefit-title {
                    font-size: 2.65rem;
                    line-height: 1.5;
                    font-weight: 600;
                    color: #ffffff;
                    margin: 0px;
                }
                .benefit-description {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    white-space: nowrap;
                    color: #ffffff;
                    margin: 0px;
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .benefits {
        .whole-benefits-content {
            .benefit-box {
                .benefit-text-container {
                    .benefit-title {
                        font-size: 2.25rem;
                    }
                    .benefit-description {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .benefits {
        padding: 12.5vh 4.5vw;
        background-size: cover;
        gap: 1vw;

        .whole-benefits-content {
            gap: 50px;
            .benefit-box {
                img {
                    width: 6vw;
                }

                .benefit-text-container {
                    .benefit-title {
                        font-size: 1.4rem;
                    }
                    .benefit-description {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .benefits {
        flex-direction: column;
        background-size: 200%;
        background-repeat: no-repeat;
        background-position-y: 10%;

        .img-container {
            width: 90vw;
            margin-bottom: 7vh;
        }

        .whole-benefits-content {
            gap: 5vh;

            .benefit-box {
                padding: 0 2.5vw;
                gap: 5vw;

                img {
                    width: 12.5vw;
                }

                .benefit-text-container {
                    .benefit-description {
                        font-size: 0.9rem;
                        white-space: normal;
                    }
                }
            }
        }
    }
}
