#apiDemo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15vw;
  padding-bottom: 15vh;
}
#apiDemo h3 {
  font-weight: bold;
  width: fit-content;
}
#apiDemo .ant-segmented {
  background-color: #545454;
  padding: 7.5px 2.5px;
  border-radius: 10px;
  margin-top: 3vh;
}
#apiDemo .ant-segmented .ant-segmented-group {
  align-items: center;
}
#apiDemo .ant-segmented .ant-segmented-group .ant-segmented-item {
  margin: 0 10px;
  border-radius: 10px;
  padding: 10px;
  color: #e4e4e4;
}
#apiDemo .ant-segmented .ant-segmented-group .ant-segmented-item:hover {
  color: #191919;
}
#apiDemo .ant-segmented .ant-segmented-group .ant-segmented-item-selected,
#apiDemo .ant-segmented .ant-segmented-thumb {
  border-radius: 10px;
  background: #83e4fc;
  color: #191919;
}
#apiDemo .demo-box {
  position: relative;
  display: flex;
  background-color: #545454;
  border-radius: 15px;
  padding: 65px;
  margin-top: 7.5vh;
}
#apiDemo .demo-box .demo-images {
  display: flex;
  flex-direction: column;
}
#apiDemo .demo-box .demo-images .img-demo {
  height: 15vh;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
#apiDemo .demo-box .demo-images .img-demo:hover {
  opacity: 0.7;
}
#apiDemo .demo-box .demo-images .img-demo:nth-child(2) {
  margin: 15px 0;
}
#apiDemo .demo-box .demo-content {
  display: flex;
  margin-left: 1.5vw;
}
#apiDemo .demo-box .demo-content .upload-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid #83e4fc;
  border-radius: 10px;
  background-color: #83e4fc1a;
  box-shadow: inset 15.36px 15.36px 15.36px #0000001a;
  height: calc(45vh + 30px);
  min-width: calc((45vh + 30px) / 1.25);
}
#apiDemo .demo-box .demo-content .upload-label button {
  display: flex;
  position: relative;
  border: none;
  background-color: unset;
  height: fit-content;
}
#apiDemo .demo-box .demo-content .upload-label button img {
  height: calc(45vh + 20px);
  width: auto;
  border-radius: 5px;
  transition: all 0.3s;
}
#apiDemo .demo-box .demo-content .upload-label button div {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0000004c;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: all 0.5s;
}
#apiDemo .demo-box .demo-content .upload-label button:hover div {
  opacity: 1;
}
#apiDemo .demo-box .demo-content .upload-label button .btn-cancel {
  filter: drop-shadow(6.14px 6.14px 6.14px #0000004c);
  width: 65px;
  height: 65px;
  cursor: pointer;
}
#apiDemo .demo-box .demo-content .btn-next {
  background-color: unset;
  border: none;
  margin: 0 1.5vw;
  cursor: pointer;
}
#apiDemo .demo-box .demo-content .btn-next svg {
  filter: drop-shadow(6px 6px 6px #0000004c);
  width: 65px;
  height: 65px;
}
#apiDemo .demo-box .demo-content .upload-label button .btn-cancel g ellipse,
#apiDemo .demo-box .demo-content .btn-next svg g circle {
  fill: #83e4fc;
  transition: all 0.3s;
}
#apiDemo
  .demo-box
  .demo-content
  .upload-label
  button
  .btn-cancel
  g
  ellipse:hover,
#apiDemo .demo-box .demo-content .btn-next svg g circle:hover {
  fill: #87d0e2;
}
#apiDemo
  .demo-box
  .demo-content
  .upload-label
  button
  .btn-cancel
  g
  ellipse:active,
#apiDemo .demo-box .demo-content .btn-next svg g circle:active {
  fill: #8bbdc8;
}
#apiDemo .demo-box .demo-content .btn-next-invalid {
  cursor: default;
}
#apiDemo .demo-box .demo-content .btn-next-invalid svg g circle,
#apiDemo .demo-box .demo-content .btn-next-invalid svg g circle:hover {
  fill: #d9d9d9;
}
#apiDemo .demo-box .demo-content .result-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#apiDemo .demo-box .demo-content .result-img-box {
  border: 5px solid #d4d4d4;
  border-radius: 10px;
  display: flex;
  height: calc(45vh + 30px);
  min-width: calc((45vh + 30px) / 1.25);
}
#apiDemo .demo-box .demo-content .result-img-box img {
  border-radius: 5px;
  height: 100%;
}
#apiDemo .demo-box .demo-content .carousel-button-box {
  margin-top: 20px;
}
#apiDemo .demo-box .demo-content .carousel-button-box button {
  margin: 0 20px;
  background-color: unset;
  border: none;
  transition: all 0.3s;
}
#apiDemo .demo-box .demo-content .carousel-button-box button:hover {
  scale: 0.85;
}
#apiDemo .demo-box .demo-content .carousel-button-box button svg {
  width: 10px;
  height: 20px;
}

#apiDemo .loading-screen {
  position: absolute;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
#apiDemo .loading-screen img {
  width: 5vw;
  margin-bottom: 1.5vh;
}
#apiDemo .loading-screen .ant-progress {
  width: 15vw;
  margin-bottom: 2vh;
}
#apiDemo .loading-screen .ant-progress .ant-progress-inner {
  background-color: #4e4e4e;
  padding: 2px;
}
#apiDemo .loading-screen .ant-progress .ant-progress-bg {
  background-color: #83e4fc;
}
#apiDemo .btn-getAccess {
  margin-top: 7.5vh;
}

@media (max-width: 1024px) {
  #apiDemo .ant-segmented {
    margin-top: 2.5vh;
  }
  #apiDemo .demo-box {
    padding: 50px;
    margin-top: 5vh;
    flex-direction: column;
    align-items: center;
    min-width: 80vw;
  }
  #apiDemo .demo-box .demo-images {
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 0;
    order: 2;
  }
  #apiDemo .demo-box .demo-images .img-demo {
    height: 12.5vh;
  }
  #apiDemo .demo-box .demo-images .img-demo:nth-child(2) {
    margin: 0 25px;
  }
  #apiDemo .demo-box .demo-content {
    margin-left: 0;
  }
  #apiDemo .demo-box .demo-content .upload-label,
  #apiDemo .demo-box .demo-content .result-img-box {
    min-width: unset;
    width: 35vw;
    height: fit-content;
    min-height: calc(35vw * 1.25);
  }
  #apiDemo .demo-box .demo-content .upload-label button img {
    width: 35vw;
    height: auto;
  }
  #apiDemo .demo-box .demo-content .carousel-button-box {
    margin-top: 15px;
  }
  #apiDemo .loading-screen img {
    width: 12.5vw;
  }
  #apiDemo .loading-screen .ant-progress {
    width: 25vw;
    margin-bottom: 1.5vh;
  }
  #apiDemo .loading-screen p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  #apiDemo .ant-segmented .ant-segmented-group .ant-segmented-item {
    padding: 7.5px;
  }
  #apiDemo .demo-box {
    padding: 35px 25px;
  }
  #apiDemo .demo-box .demo-images .img-demo {
    height: 13.5vh;
  }
  #apiDemo .demo-box .demo-images .img-demo:nth-child(2) {
    margin: 0 20px;
  }
  #apiDemo .demo-box .demo-content .upload-label,
  #apiDemo .demo-box .demo-content .result-img-box {
    width: 32.5vw;
    min-height: calc(32.5vw * 1.25);
  }
  #apiDemo .demo-box .demo-content .upload-label button img {
    width: 32.5vw;
  }
  #apiDemo .demo-box .demo-content .btn-next {
    margin: 0 2.5vw;
  }
  #apiDemo .demo-box .demo-content .btn-next svg,
  #apiDemo .demo-box .demo-content .upload-label button .btn-cancel {
    width: 45px;
    height: 45px;
  }
  #apiDemo .demo-box .demo-content .carousel-button-box {
    margin-top: 5px;
  }
  #apiDemo .demo-box .demo-content .carousel-button-box button {
    margin: 0 15px;
  }
  #apiDemo .demo-box .demo-content .carousel-button-box button svg {
    width: 7.5px;
    height: 15px;
  }
  #apiDemo .loading-screen img {
    width: 20vw;
  }
  #apiDemo .loading-screen .ant-progress {
    width: 45vw;
  }
  #apiDemo .loading-screen p {
    font-size: 1.1rem;
  }
}
