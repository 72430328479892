.cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 20vh 0;
    gap: 4.5vh;

    .cta-title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .cta-title {
            width: 62.5vw;
            font-weight: 600;
            font-size: 4.25rem;
            line-height: 1.5;
            color: #fff;
            text-align: center;
            margin-bottom: 0;
        }

        .cta-subtitle {
            color: #fff;
            text-align: center;
        }
    }

    .btn-start-now {
        background-color: #f1fcff;
        color: #000000;
        border-radius: 30px;
        font-size: 1.325rem;
        font-weight: 600;
        padding: 12.5px 50px;
        border: none;
        transition: all 0.3s;

        &:hover {
            background-color: #d3f4fb;
        }

        &:active {
            background-color: #59d7ee;
        }
    }
}

@media (max-width: 1600px) {
    .cta {
        .cta-title-container {
            .cta-title {
                font-size: 4rem;
            }
        }
    }
}

@media (max-width: 1024px) {
    .cta {
        padding: 15vh 0;
        gap: 3.5vh;

        .cta-title-container {
            .cta-title {
                font-size: 2.5rem;
            }

            .cta-subtitle {
                font-size: 1.5rem;
            }
        }
    }
}

@media (max-width: 820px) {
    .cta {
        .cta-title-container {
            .cta-title {
                font-size: 2.5rem;
            }
        }
    }
}

@media (max-width: 480px) {
    .cta {
        gap: 5vh;

        .cta-title-container {
            width: 80vw;

            .cta-title {
                font-size: 1.75rem;
            }

            .cta-subtitle {
                font-size: 1.15rem;
            }
        }

        .btn-start-now {
            padding: 10px 60px;
            font-size: 1.3rem;
        }
    }
}
