$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;

.delete {
    height: 100vh;
    display: flex;
    padding: 8vh 0;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    background-image: url("../../../assets/img/dentist/bg_login.webp");
    background-size: cover;
    background-position: center;

    .btn-toDashboard {
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        color: $main-color;
        margin-top: 30px;
        transition: all 0.3s;

        &:hover {
            color: $main-color-hover;
        }

        &:active {
            color: $main-color-active;
        }
    }
}

.delete-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $gray-color-1;
    background-color: $gray-color-5;
    padding: 40px;
    border-radius: 8px;
    margin-top: 3.5vh;

    h4,
    p {
        color: $gray-color-1;
    }

    .delete-title-name {
        font-size: 1.2rem;
        color: $main-color;
        font-weight: 600;
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0px;
    }

    .otherContainer {
        display: flex;

        .otherInput {
            border: 2px solid $gray-color-2;
            background-color: unset;
            border-radius: 4px;
            padding: 5px 15px;
            font-size: 16px;
            color: $gray-color-1;

            &::placeholder {
                color: $gray-color-2;
            }
        }
    }

    .input-textBox {
        width: 25vw;
        border: 2px solid $gray-color-2;
        background-color: unset;
        border-radius: 4px;
        padding: 10px 15px;
        font-size: 18px;
        color: $gray-color-1;
        margin: 20px 0 10px 0;

        .ant-input {
            background-color: unset;
            border: none;
            color: $gray-color-1;

            &::placeholder {
                font-size: 18px;
                color: $gray-color-2;
            }
        }

        .ant-input-suffix {
            img {
                width: 22.5px;
                cursor: pointer;
            }
        }
    }

    .btn-delete {
        padding: 10px 25px;
        background-color: $warning-color;
        color: $gray-color-5;
        border: unset;
        border-radius: 5px;
        margin-top: 30px;
        font-weight: 600;
        transition: all 0.3s;

        &:hover {
            background-color: darken($warning-color, 10%);
        }
    }

    .btn-logout {
        font-size: 16px;
        font-weight: 500;
        background-color: unset;
        border: unset;
        color: $gray-color-2;
        margin-top: 15px;
        transition: all 0.3s;

        span {
            font-size: 16px;
            font-weight: 500;
            text-decoration: underline;
        }

        &:hover {
            color: $gray-color-3;
        }

        &:active {
            color: $gray-color-2;
        }
    }
}

.delete-modal {
    width: 500px !important;

    .logo {
        width: 35px;
        margin-bottom: 10px;
    }

    .ant-modal-confirm .ant-modal-body {
        padding: 20px;
    }

    .ant-modal-confirm-body {
        .ant-modal-confirm-title {
            font-size: 1rem;
            font-weight: 600;
        }

        .ant-modal-confirm-content {
            font-size: 16px;
        }
    }

    .ant-btn {
        border-radius: 5px;
        height: auto;
        padding: 2px 15px;
        background-color: $gray-color-2;
        border: none;
        color: $gray-color-1;
        transition: all 0.3s;

        &:hover {
            background-color: $gray-color-3;
            color: $gray-color-1;
        }
    }

    .ant-btn-dangerous {
        background-color: $warning-color;

        &:hover {
            background-color: darken($warning-color, 10%);
        }
    }
}

.ant-radio-checked .ant-radio-inner {
    border-color: $main-color;
}
.ant-radio-inner::after {
    border: 1px solid $main-color;
    background-color: $main-color;
}

@media (max-width: 1024px) {
    .delete-container {
        width: 75vw;

        .input-textBox {
            width: 50vw;
        }
    }
}

@media (max-width: 480px) {
    .delete-container {
        width: 85vw;
        padding: 30px 25px;

        .delete-title-name {
            font-size: 1rem;
        }

        .otherContainer {
            .otherInput {
                font-size: 15px;
            }
        }

        .radio-text {
            font-size: 16px;
            text-align: start;
        }

        .input-textBox {
            width: 65vw;
        }

        .btn-delete {
            font-size: 16px;
        }
    }
}
