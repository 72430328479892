$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;

.dashboard {
    position: relative;
    min-height: 100vh;
    padding: 7.5vh 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("../../../assets/img/dentist/bg_login.webp");
    background-size: cover;
    background-position: center;

    .logo {
        width: 12.5vw;
        margin-bottom: 1.5vh;
        transition: all 0.3s;

        &:hover {
            transform: scale(0.9);
        }
    }
}

.dashboard-content {
    justify-content: space-between;
    margin-top: 3vh;

    .info-box,
    .content-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $gray-color-6;
        border-radius: 10px;
        padding: 30px 50px;
        box-shadow: 0px 2px 5px rgba(228, 228, 228, 0.2);
    }

    .info-box {
        justify-content: space-between;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .avatar {
            width: 80%;
            margin-bottom: 10px;
        }

        .userName {
            width: fit-content;
            border-style: solid;
            border-color: $main-color;
            border-width: 0 0 3px 0;
            color: $gray-color-1;
            font-weight: 600;
        }

        // .btn-onlineAnalysis {
        //   display: inline-block;
        //   background-color: $main-color;
        //   border: 1px solid $main-color;
        //   border-radius: 15px;
        //   padding: 10px 15px;
        //   color: $gray-color-6;
        //   transition: all 0.3s;
        //   margin-bottom: 20px;

        //   &:hover {
        //     background-color: unset;
        //     color: $main-color;
        //   }
        // }

        .btn-logout {
            border: 1px solid $gray-color-1;
            border-radius: 5px;
            background-color: unset;
            padding: 10px 15px;
            color: $gray-color-1;
            transition: all 0.3s;

            &:hover {
                border-color: #e4e4e480;
                color: #e4e4e480;
            }

            img {
                width: 25px;
                margin-top: -2.5px;
                margin-left: 15px;
            }
        }
    }

    .content-box {
        background-color: #030303;
        align-items: flex-start;

        .ant-tabs {
            width: 100%;

            .ant-tabs-tab-active .ant-tabs-tab-btn {
                color: $main-color;
            }
        }

        .ant-tabs-tab {
            color: $gray-color-1;
        }

        .ant-tabs-tab + .ant-tabs-tab {
            margin: 0 0 0 40px;
        }

        .ant-tabs-ink-bar {
            background: $main-color;
        }

        .ant-tabs-content-holder {
            min-height: 60vh;
        }
    }
}

@media (max-width: 1024px) {
    .dashboard {
        padding: 7.5vh 7.5vw;

        .logo {
            width: 20vw;
        }
    }

    .dashboard-content {
        width: 80vw;
        margin-top: 1vh;

        .info-box {
            flex-direction: row;
            padding: 25px 40px;

            div {
                flex-direction: row;
            }

            .avatar {
                width: 15%;
            }

            .userName {
                margin-left: 25px;
                margin-bottom: 0;
            }

            .btn-logout img {
                display: none;
            }
        }

        .content-box {
            margin-top: 3.5vh;

            .ant-tabs-content-holder {
                min-height: 45vh;
            }
        }
    }
}

@media (max-width: 768px) {
    .dashboard-content {
        width: 85vw;
    }
}

@media (max-width: 480px) {
    .dashboard {
        padding: 7.5vh 5vw;

        .logo {
            width: 30vw;
        }
    }
    .dashboard-content {
        width: 90vw;

        .info-box,
        .content-box {
            padding: 25px 25px;
        }

        .info-box {
            .avatar {
                width: 60px;
                height: 60px;
            }

            .userName {
                font-size: 1rem;
                line-height: 2rem;
                margin-left: 10px;
            }

            .btn-logout {
                padding: 5px 12.5px;
                font-size: 0.8rem;
            }
        }
    }
}
