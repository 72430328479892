$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;

.billing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  margin-top: 2.5vh;

  .billing-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #1c1c1c;
    border-radius: 10px;
    padding: 20px 25px;
    align-items: flex-end;
    margin-bottom: 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);

    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;

      .billing-title {
        color: $gray-color-1;
        border-style: solid;
        border-color: $main-color;
        border-width: 0 0 3px 0;
        padding-bottom: 5px;
        margin-bottom: 20px;
      }

      p {
        color: $gray-color-1;
        margin-bottom: 10px;
      }
    }

    .info-btns {
      width: fit-content;
      display: flex;

      a:first-child {
        margin-right: 15px;
      }

      a:last-child {
        margin-right: 0;
      }
    }

    .btn-edit {
      width: fit-content;
      background-color: $main-color;
      border: none;
      color: #000;
      border-radius: 5px;
      padding: 7.5px 20px;
      margin-right: 20px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6));
      transition: all 0.3s;

      &:hover {
        background-color: $main-color-hover;
      }

      &:active {
        background-color: $main-color-active;
      }
    }

    .btn-unsubscribe {
      border: 1.5px solid $gray-color-1;
      color: $gray-color-1;
      border-radius: 5px;
      padding: 7.5px 20px;
      transition: all 0.3s;

      &:hover {
        border-color: $gray-color-2;
        color: $gray-color-2;
      }
    }
  }
}

@media (max-width: 480px) {
  .billing-content {
    padding: 20px;

    .info-btns {
      flex-direction: column;
      align-items: flex-end;

      a:first-child {
        margin-bottom: 15px;
      }
      a:last-child {
        margin-bottom: 0;
      }
    }

    .btn-edit {
      margin-right: 0;
      margin-bottom: 15px;
    }

    .btn-edit,
    .btn-unsubscribe {
      font-size: 0.9rem;
    }
  }
}
