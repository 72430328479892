.newsletter {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15vh 8.5vw;

    .newsletter-title {
        font-weight: 600;
        font-size: 3.35rem;
        line-height: 1.4;
        color: #fff;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .newsletter-subtitle {
        font-size: 1.35rem;
        line-height: 1.25;
        color: #fff;
        text-align: center;
        margin-bottom: 5rem;
    }

    .message-container {
        display: flex;
        width: 40%;

        .ant-input-group {
            width: 100%;
            align-items: flex-start;

            .input-item {
                p {
                    font-size: 1rem;
                    color: #fafafa;
                    margin-bottom: 0.5rem;
                }
            }

            .ant-input {
                width: 100%;
                height: 48px;
                padding: 0 25px;
                font-size: 0.95rem;
                margin-bottom: 30px;
                color: #fff;
                background-color: rgba(#fff, 0.04);
                border: 1px solid #fff;
                border-radius: 4px;
                &::placeholder {
                    color: #888;
                }
                &:focus {
                    border-color: #65d0ea;
                }
            }
            .ant-select {
                width: 100%;
                height: 48px;
                color: #fff;
                margin-bottom: 30px;

                &.ant-select-focused {
                    .ant-select-selector {
                        border-color: #65d0ea !important;
                    }
                }
                .ant-select-selector {
                    height: 100%;
                    background-color: rgba(#fff, 0.04);
                    border: 1px solid #fff;
                    padding: 0 25px;
                }
                .ant-select-selection-placeholder {
                    color: #888888;
                }
                .ant-select-selection-item {
                    color: white;
                    line-height: 48px;
                    &[title="Select a job title*"] {
                        color: gray;
                    }
                }
                .ant-select-arrow {
                    right: 25px;
                    color: white;
                    scale: 1.25 1;
                }
                .ant-select-selection-placeholder {
                    line-height: 48px;
                }
            }
        }

        .btn-subscribe-newsletter {
            width: 100%;
            height: 54px;
            background-color: #fff;
            border-style: none;
            color: #333;
            padding: 5px 25px;
            border-radius: 27px;
            transition: all 0.3s;

            &:hover {
                background-color: #87d0e2;
            }

            &:active {
                background-color: #8bbdc8;
            }
            &:disabled {
                background-color: #6f6f6f;
            }
            span {
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }
}

@media (max-width: 1600px) {
    .newsletter {
        .newsletter-title {
            font-size: 2.45rem;
        }
    }
}

@media (max-width: 1024px) {
    .newsletter {
        padding: 12.5vh 8.5vw;

        .newsletter-title {
            font-size: 1.95rem;
        }

        .message-container {
            width: 100%;
        }
    }
}

@media (max-width: 480px) {
    .newsletter {
        padding: 15vh 5vw;

        .newsletter-title {
            font-size: 1.5rem;
        }

        .newsletter-subtitle {
            margin-bottom: 2.5rem;
        }
    }
}
