$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;

.editProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 3.5vh;

  form {
    width: 100%;
  }

  .editComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      color: $gray-color-1;
      margin-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .input-textBox {
      width: 25vw;
      border: 2px solid $gray-color-2;
      background-color: unset;
      border-radius: 4px;
      padding: 5px 15px;
      font-size: 16px;
      color: $gray-color-1;

      .ant-input {
        background-color: unset;
        border: none;
        color: $gray-color-1;

        &::placeholder {
          font-size: 16px;
          color: #444444;
        }
      }

      .ant-select-selector {
        background-color: unset;
        border: none;
        padding: 0;

        .ant-select-selection-item {
          text-align: left;
          font-size: 16px;
          color: $gray-color-1;
        }

        .ant-select-selection-placeholder {
          text-align: left;
          font-size: 16px;
          color: $gray-color-2;
        }
      }

      .ant-select-arrow svg {
        fill: $gray-color-1;
        width: 15px;
        height: 12.5px;
      }

      &::placeholder {
        font-size: 16px;
        color: $gray-color-2;
      }
    }

    .name {
      display: flex;

      .input-textBox {
        width: calc((25vw - 10px) / 2);

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    .phone-input {
      width: 25vw;
      font-size: 16px;
      color: $gray-color-1;

      .PhoneInputCountry,
      .PhoneInputInput {
        border: 2px solid $gray-color-2;
        background-color: unset;
        padding: 5px 15px;
        border-radius: 4px;
      }

      .PhoneInputInput {
        width: 100%;
        margin-left: 5px;

        &::placeholder {
          font-size: 16px;
          color: $gray-color-2;
        }
      }

      .PhoneInputCountrySelectArrow {
        width: 7.5px;
        height: 7.5px;
        border-color: $gray-color-1;
        border-bottom-width: 1.5px;
        border-right-width: 1.5px;
        opacity: 1;
        margin-left: 12.5px;
        margin-bottom: 2.5px;
      }
    }

    .btn-changePass {
      text-decoration: underline;
      transition: 0.5s;

      &:hover {
        color: $gray-color-2;
      }
    }
  }

  .btn-save {
    padding: 7.5px 35px;
    background-color: $main-color;
    border: none;
    color: #333;
    border-radius: 5px;
    font-weight: 600;
    transition: 0.5s;
    margin-top: 45px;
    margin-bottom: 20px;

    &:hover {
      background-color: $main-color-hover;
    }

    &:active {
      background-color: $main-color-active;
    }
  }

  .btn-delete {
    color: $gray-color-2;
    font-size: 16px;
    text-decoration: underline;
    transition: 0.5s;

    &:hover {
      color: $gray-color-3;
    }
  }
}

.btn-ok {
  padding: 3px 20px;
  border-radius: 10px;
  background-color: $main-color;
  border: none;
  color: #333;
  transition: 0.5s;

  &:hover {
    background-color: $main-color-hover;
  }

  &:active {
    background-color: $main-color-active;
  }

  &:disabled {
    background-color: $gray-color-2;
  }
}

@media (max-width: 1024px) {
  .editProfile {
    justify-content: center;

    .editComponent {
      p {
        font-size: 0.95rem;
      }

      .input-textBox,
      .phone-input {
        width: 35vw;
      }

      .name {
        .input-textBox {
          width: calc((35vw - 10px) / 2);
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .editProfile {
    .editComponent {
      width: 80vw;

      p {
        font-size: 0.9rem;
      }

      .input-textBox,
      .phone-input {
        width: 57.5vw;
      }

      .name {
        .input-textBox {
          width: calc((57.5vw - 10px) / 2);
        }
      }

      .phone-input {
        .PhoneInputCountry,
        .PhoneInputInput {
          padding: 5px 10px;
        }

        .PhoneInputCountrySelectArrow {
          width: 7px;
          height: 7px;
          margin-left: 10px;
        }

        .PhoneInputCountryIcon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
