$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;

.onboarding {
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .onboarding-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $gray-color-6;
        border-radius: 8px;
        padding: 5vh 2.5vw;
        padding-bottom: 7.5vh;
        filter: drop-shadow(0px 2px 4px #27272740);

        .title {
            color: #fff;
            font-weight: bold;
            margin-bottom: 4vh;
        }

        .btn-back {
            position: absolute;
            top: calc(5vh + 5px);
            left: 2.5vw;
            border: none;
            background-color: unset;

            svg {
                width: 22px;
                height: 22px;

                path {
                    fill: $gray-color-1;
                    transition: all 0.3s;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: $gray-color-2;
                    }
                }
            }

            &:active {
                svg {
                    path {
                        fill: $gray-color-3;
                    }
                }
            }
        }

        .dropdown-box,
        .input-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 15px;

            p {
                margin-bottom: 5px;
                color: $gray-color-1;
                font-size: 16px;

                span {
                    color: $gray-color-3;
                    font-size: 16px;
                }
            }
        }

        .country-region {
            display: flex;
            align-items: center;
            gap: 10px;

            .dropdown-box {
                .input-textBox {
                    width: calc((30vw - 10px) / 2);
                    border: 2px solid $gray-color-2;
                    background-color: unset;
                    border-radius: 4px;
                    padding: 5px 15px;
                    font-size: 16px;
                    color: $gray-color-1;
                }
            }
        }

        .input-box {
            color: $gray-color-1;

            .phone-input {
                width: 30vw;
                font-size: 16px;

                .PhoneInputCountry,
                .PhoneInputInput {
                    border: 2px solid $gray-color-2;
                    background-color: unset;
                    padding: 5px 15px;
                    border-radius: 4px;
                }

                .PhoneInputInput {
                    width: 100%;
                    margin-left: 5px;

                    &::placeholder {
                        font-size: 16px;
                        color: #444444;
                    }
                }

                .PhoneInputCountrySelectArrow {
                    width: 7.5px;
                    height: 7.5px;
                    border-color: $gray-color-1;
                    border-bottom-width: 1.5px;
                    border-right-width: 1.5px;
                    opacity: 1;
                    margin-left: 12.5px;
                    margin-bottom: 2.5px;
                }
            }

            .input-textBox {
                width: 30vw;
                border: 2px solid $gray-color-2;
                background-color: unset;
                border-radius: 4px;
                padding: 5px 15px;
                font-size: 16px;
                color: $gray-color-1;

                .ant-select-selector {
                    background-color: unset;
                    border: none;
                    padding: 0;

                    .ant-select-selection-item {
                        text-align: left;
                        font-size: 16px;
                        color: $gray-color-1;
                    }

                    .ant-select-selection-placeholder {
                        text-align: left;
                        font-size: 16px;
                        color: $gray-color-3;
                    }
                }

                .ant-select-arrow svg {
                    fill: $gray-color-1;
                    width: 15px;
                    height: 12.5px;
                }
            }
        }

        .btn-main {
            width: 100%;
            padding: 7.5px 25px;
            background-color: $main-color;
            border: none;
            color: $gray-color-5;
            font-weight: bold;
            border-radius: 5px;
            margin-top: 20px;
            transition: all 0.3s;

            &:hover {
                background-color: $main-color-hover;
            }

            &:active {
                background-color: $main-color-active;
            }
        }

        .step {
            position: absolute;
            bottom: 0;
            display: flex;
            justify-self: flex-end;
            width: 100%;
            height: fit-content;
            background-color: #444444;
            border-radius: 50px;

            .progress-bar {
                width: 50%;
                height: 6px;
                background-color: $main-color;
                border-radius: 50px;
                transition: all 0.3s;
            }
        }
    }
}

@media (max-width: 1024px) {
    .onboarding {
        .onboarding-content {
            padding: 2.5vh 4vw;
            padding-bottom: 4.5vh;

            .title {
                margin-bottom: 2.5vh;
            }

            .btn-back {
                top: calc(2.5vh + 5px);
                left: 4vw;
            }

            .country-region {
                .dropdown-box {
                    .input-textBox {
                        width: calc((50vw - 10px) / 2);
                    }
                }
            }

            .input-box {
                .phone-input {
                    width: 50vw;
                }

                .input-textBox {
                    width: 50vw;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .onboarding {
        .onboarding-content {
            padding: 2.5vh 6.5vw;
            padding-bottom: 4.5vh;

            .btn-back {
                top: 2.5vh;
                left: 6.5vw;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            .country-region {
                flex-direction: column;
                gap: 0px;

                .dropdown-box {
                    .input-textBox {
                        width: 75vw;
                    }
                }
            }

            .input-box {
                .phone-input,
                .input-textBox {
                    width: 75vw;
                }
            }
        }
    }
}
