$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$smile-care-main-color: #4bd7f3;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;
$gray-btn-color: #4b4b4b;
$gray-btn-color-hover: #313131;

.payment-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vh 10vw 5vh 10vw;
    gap: 5vh;
    background-image: url("../../assets/img/dentist/bg_payment.webp");
    background-size: cover;
    background-position: bottom center;

    p {
        margin-bottom: 0;
    }

    .payment-title {
        font-weight: bold;
        font-style: italic;
        color: #fff;
        font-size: 1.8rem;
        line-height: 1.5;
    }

    .payment-footer {
        margin-top: auto;
        font-size: 0.85rem;
        color: #b8b8b8;

        a {
            font-size: 0.85rem;
            color: $gray-color-1;
        }
    }
}

@media (max-width: 1024px) {
    .payment-page {
        padding: 10vh 10vw 5vh 10vw;
        gap: 2.5vh;

        .payment-title {
            text-align: center;
            font-size: 1.55rem;
        }
    }
}

@media (max-width: 480px) {
    .payment-page {
        padding: 12vh 10vw 5vh 10vw;
        min-height: 100vh;
        height: fit-content;

        .payment-title {
            font-size: 1.2rem;
        }

        .payment-footer,
        .payment-footer a {
            font-size: 0.75rem;
            margin-top: 2.5vh;
            text-align: center;
        }
    }
}
