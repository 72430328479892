$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$smile-care-main-color: #4bd7f3;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;
$warning-color: #aa3e1c;
$gray-btn-color: #4b4b4b;
$gray-btn-color-hover: #313131;

.subscription-plans {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.5vw;

    .plan-card {
        display: flex;
        flex-direction: column;
        padding: 3vh 2.5vw;
        width: 30vw;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.08) 0%,
            rgba(255, 255, 255, 0.06) 15.26%,
            rgba(255, 255, 255, 0.01) 100%
        );
        border: 2px solid rgba(228, 228, 228, 0.1);
        backdrop-filter: blur(6px);
        border-radius: 12px;

        .plan-title {
            font-size: 2.2rem;
            line-height: 1.5;
            color: $gray-color-1;
            margin-bottom: 5px;
        }

        .premium-plan-title {
            display: flex;
            align-items: center;
            background: linear-gradient(90.43deg, #83e4fc 0.38%, #4393a2 41.65%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        .plan-description,
        .plan-description span {
            color: $gray-color-1;
            font-size: 0.9rem;
            line-height: 1.4;
        }

        .plan-divider {
            border-top: 1px solid $gray-color-3;
            margin: 30px 0;
        }

        .plan-features {
            display: flex;
            flex-direction: column;
            gap: 2vh;

            .feature-text {
                display: flex;
                gap: 10px;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-top: 2.5px;
                }

                p {
                    width: 100%;
                    font-size: 0.9rem;
                    color: $gray-color-1;
                }
            }
        }

        .btn-current-plan {
            background-color: rgb(255, 255, 255, 0.08);
            border: none;
            border-radius: 100px;
            color: $gray-color-2;
            padding: 1vh 2vw;
            margin-top: auto;
        }

        .btn-premium-get-started {
            background-color: $main-color;
            border: none;
            border-radius: 100px;
            padding: 1vh 2vw;
            margin-top: 10vh;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: $main-color-hover;
            }

            &:active {
                background-color: $main-color-active;
            }
        }
    }

    .premium-card {
        border: 1px solid $main-color;
    }
}

@media (max-width: 1600px) {
    .subscription-plans {
        gap: 5vw;

        .plan-card {
            width: 35vw;
        }
    }
}

@media (max-width: 1024px) {
    .subscription-plans {
        gap: 3.5vw;

        .plan-card {
            width: 42.5vw;
            padding: 2vh 2.5vw;

            .plan-title {
                font-size: 1.9rem;
            }

            .plan-description,
            .plan-description span,
            .plan-features .feature-text p {
                font-size: 0.8rem;
            }

            .plan-features {
                gap: 1.25vh;

                .feature-text {
                    svg {
                        width: 17.5px;
                        height: 17.5px;
                    }
                }
            }

            .btn-current-plan,
            .btn-premium-get-started {
                padding: 0.8vh 2vw;
            }

            .btn-premium-get-started {
                margin-top: 7.5vh;
            }
        }
    }
}

@media (max-width: 480px) {
    .subscription-plans {
        grid-template-columns: 1fr;
        gap: 2.5vh;

        .plan-card {
            width: 85vw;
            padding: 2.5vh 5vw;

            .plan-title {
                font-size: 1.6rem;
            }

            .plan-divider {
                margin: 20px 0;
            }

            .plan-features {
                margin-top: 0.5vh;

                .feature-text {
                    svg {
                        margin-top: 3.5px;
                    }
                }
            }

            .btn-current-plan,
            .btn-premium-get-started {
                margin-top: 5vh;
                font-size: 0.85rem;
            }
        }
    }
}
