$main-color: #83e4fc;
$main-color-hover: #87d0e2;
$main-color-active: #8bbdc8;
$gray-color-1: #e4e4e4;
$gray-color-2: #9d9d9d;
$gray-color-3: #686868;
$gray-color-4: #272727;
$gray-color-5: #1e1e1e;
$gray-color-6: #1a1a1a;

.register {
    min-height: 100vh;
    padding: 7vh 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/img/dentist/bg_login.webp");
    background-size: cover;
    background-position: center;

    .register-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff0d;
        border-radius: 8px;
        padding: 5vh 2.5vw;
        margin-top: 1.5vh;

        h3 {
            width: 100%;
            color: #fff;
            font-weight: bold;
            margin-bottom: 3.5vh;
            text-align: center;
        }

        .btn-google {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 30vw;
            padding: 7.5px 25px;
            background-color: #ffffff;
            border: none;
            color: $gray-color-3;
            font-weight: 600;
            border-radius: 5px;
            transition: all 0.3s;
            margin-bottom: 1vh;

            .icon-google {
                width: 22px;
                height: 22px;
            }

            &:hover {
                background-color: #f2f2f2;
            }

            &:active {
                background-color: #e6e6e6;
            }
        }

        .divider {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 5px;
            margin: 3vh 0;

            .divider-line {
                width: 100%;
                background-color: #333;
                height: 1px;
                border-radius: 10px;
            }

            .divider-text {
                color: $gray-color-2;
                font-size: 18px;
                font-weight: bold;
                margin: 0;
            }
        }

        .name-container {
            width: fit-content;
            display: flex;
            gap: 10px;
            justify-content: space-between;
        }

        .name-box,
        .input-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;

            p {
                margin-bottom: 5px;
                color: $gray-color-1;
                font-size: 16px;
            }

            .input-textBox {
                width: 100%;
                border: 2px solid $gray-color-2;
                background-color: unset;
                border-radius: 4px;
                padding: 5px 15px;
                font-size: 16px;
                color: $gray-color-1;

                &::placeholder {
                    font-size: 16px;
                    color: $gray-color-3;
                }

                .ant-input {
                    background-color: unset;
                    border: none;
                    color: $gray-color-1;

                    &::placeholder {
                        font-size: 16px;
                        color: $gray-color-3;
                    }
                }

                .ant-input-suffix {
                    img {
                        width: 22.5px;
                        cursor: pointer;
                    }
                }
            }
        }

        .name-box {
            width: calc(15vw - 5px);
        }

        .input-box {
            width: 30vw;
        }

        .checkbox-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 2.5vh 0;
            margin-bottom: 3.5vh;
            gap: 12.5px;

            .ant-checkbox-wrapper {
                margin: 0;

                .ant-checkbox-inner {
                    border-color: $gray-color-1;
                    background-color: unset;
                }

                .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: unset;
                    border-color: $main-color;

                    &::after {
                        border-color: $main-color;
                    }
                }

                p {
                    font-size: 16px;
                    color: $gray-color-2;
                    margin-bottom: 0;
                    margin-left: 3.5px;

                    button {
                        font-size: 16px;
                        background-color: unset;
                        border: none;
                        color: $main-color;
                        text-decoration: underline;
                        transition: all 0.3s;

                        &:hover {
                            color: $main-color-hover;
                        }

                        &:active {
                            color: $main-color-active;
                        }
                    }
                }
            }
        }

        .btn-main {
            width: 30vw;
            padding: 7.5px 25px;
            background-color: $main-color;
            border: none;
            color: $gray-color-5;
            font-weight: bold;
            border-radius: 5px;
            transition: all 0.3s;

            &:hover {
                background-color: $main-color-hover;
            }

            &:active {
                background-color: $main-color-active;
            }
        }
    }

    .login-text {
        color: $gray-color-1;
        margin: 4vh 0;

        .login-link {
            color: $main-color;
            text-decoration: underline;
            transition: all 0.3s;

            &:hover {
                color: $main-color-hover;
            }

            &:active {
                color: $main-color-active;
            }
        }
    }
}

.error-message {
    margin-top: 85vh;

    .ant-message-notice-content {
        background-color: #ffffffb3;
        border-radius: 100px;

        .anticon {
            color: #aa3e1c;
        }

        span {
            font-size: 16px;
        }
    }
}

.registering-modal {
    .ant-modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .registering-text {
            margin-top: 20px;
            margin-bottom: 7.5px;

            &::after {
                content: "";
                animation: ellipsis 1.5s infinite;
            }
        }
    }
}

.privacy-modal {
    .ant-modal-content {
        overflow-y: scroll;
        width: 55vw;
        height: 80vh;
        padding: 3.5vh 3.5vw;

        .ant-modal-close {
            position: fixed;
            top: unset;
            right: unset;
            margin-left: 46vw;
            margin-top: -3vh;
        }

        .ant-modal-body {
            .privacyBg,
            .termsBg {
                width: unset;
                padding: 0;
            }
        }
    }
}

@keyframes ellipsis {
    0% {
        content: "";
    }
    25% {
        content: ".";
    }
    50% {
        content: "..";
    }
    75% {
        content: "...";
    }
}

@media (max-width: 1024px) {
    .register {
        .register-container {
            padding: 2.5vh 4vw;

            h3 {
                margin-bottom: 2.5vh;
            }

            .btn-google {
                width: 50vw;

                .icon-google {
                    width: 20px;
                    height: 20px;
                }
            }

            .divider {
                margin: 1.5vh 0;
            }

            .name-box {
                width: calc(25vw - 5px);
            }

            .input-box {
                width: 50vw;
            }

            .checkbox-container {
                margin: 1.5vh 0;
                margin-bottom: 2.5vh;

                .ant-checkbox-wrapper {
                    p {
                        margin-left: 2.5px;
                    }
                }
            }

            .btn-main {
                width: 50vw;
            }
        }

        .login-text {
            margin: 2.5vh 0;
        }
    }
}

@media (max-width: 480px) {
    .register {
        .register-container {
            padding: 2.5vh 6.5vw;

            .btn-google {
                width: 75vw;
                font-size: 16px;

                .icon-google {
                    width: 18px;
                    height: 18px;
                }
            }

            .divider {
                margin: 2vh 0;

                .divider-text {
                    font-size: 16px;
                }
            }

            .name-container {
                flex-direction: column;
                justify-content: center;
                gap: 0;
            }

            .name-box,
            .input-box {
                .input-textBox {
                    .ant-input-suffix {
                        img {
                            width: 20px;
                        }
                    }
                }
            }

            .name-box,
            .input-box {
                width: 75vw;
            }

            .checkbox-container {
                width: 75vw;
            }

            .btn-main {
                width: 75vw;
            }
        }
    }

    .privacy-modal {
        .ant-modal-content {
            width: 80vw;
            padding: 2.5vh 3vw;

            .ant-modal-close {
                margin-left: 64.5vw;
            }
        }
    }
}
