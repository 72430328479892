#patientPage {
  overflow-x: hidden;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.data-container {
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  margin: 15vh 0;
  padding: 10vh 10vw;
  position: relative;
}
.data-container svg {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -5vh;
}
.data-container h2 {
  color: #4bd7f3;
}
.data-container h4 {
  text-align: center;
}
.faq-container {
  margin-top: 25vh;
  margin-bottom: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#patientPage .cta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15vh;
}
#patientPage .cta-container .btn-meetAIAssistant {
  border: none;
  border-radius: 15px;
  background-color: #00c8f0b3;
  color: #fff;
  padding: 15px 45px;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 20px;
  transition: all 0.3s;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
}
#patientPage .cta-container .btn-meetAIAssistant:hover {
  background-color: #00c8f0;
}

@media (max-width: 1024px) {
  .faq-container {
    margin-top: 15vh;
    margin-bottom: 15vh;
  }
  #patientPage .cta-container h2 {
    width: 85vw;
    text-align: center;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
  .data-container {
    width: 90vw;
    padding: 7.5vh 5vw;
    margin: 10vh 0;
  }
  .faq-container h1 {
    font-size: 3rem;
  }
}
