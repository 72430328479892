/* dentist page */
.home {
    width: 100%;
    min-height: 100vh;
    padding: 2vh 0 15vh 0;
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: 50%;
    background-size: cover;
    border-radius: 0 0 10px 10px;

    .home-leftSide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15vh 0 5vh 7vw;
        gap: 1.5vh;

        .home-title {
            color: #fff;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }

        .home-sub-title {
            color: #fff;
            width: 80%;
            font-weight: normal;
        }

        .home-btnBox {
            display: flex;
            flex-direction: column;
            width: fit-content;
        }

        .home-btnBox-sm {
            display: none;
        }

        .btn-primary {
            flex: 1;
            width: fit-content;
            background: #83e4fc;
            padding: 10px 30px;
            border-style: solid;
            border-width: 2px;
            border-color: #83e4fc;
            border-radius: 10px;
            color: #333;
            font-size: 1.15rem;
            font-weight: 600;
            text-align: center;
            filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.25));
            transition: all 0.4s;

            &:hover {
                background-color: #87d0e2;
            }

            &:active {
                background-color: #8bbdc8;
            }
        }

        .btn-secondary {
            border-color: #83e4fc;
            color: #83e4fc;
            padding: 10px 30px;
            border-radius: 15px;
            border-style: solid;
            border-width: 2px;
            font-size: 1.15rem;
            text-align: center;
            filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.25));
            transition: all 0.4s;
            margin-top: 30px;

            &:hover {
                color: #87d0e2;
                border-color: #87d0e2;
            }

            &:active {
                color: #8bbdc8;
                border-color: #8bbdc8;
            }
        }
    }

    .home-img {
        margin-top: 20vh;
        margin-left: -5vw;
        width: 45%;
        will-change: transform;
        animation: moveY 2.5s ease infinite;
    }
}

@keyframes moveY {
    0% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-5px);
    }
}

@media (max-width: 1024px) {
    .home {
        height: fit-content;
        min-height: unset;
        background-image: url("../../assets/img/dentist/bg_home_mobile.webp");
        background-repeat: no-repeat;
        background-position-x: 15%;
        background-position-y: 60%;
        padding: 1.5vh 0 50vh 0;
        justify-content: center;

        .home-leftSide {
            padding: 8vh 0 5vh 0;
            align-items: center;
            gap: 1vh;

            .home-title {
                width: 90%;
                margin-bottom: 15px;
                text-align: center;
            }

            .home-sub-title {
                width: 90%;
                text-align: center;
            }

            .home-btnBox {
                display: none;
            }

            .home-btnBox-sm {
                display: flex;
                flex-direction: column;
                width: fit-content;
                align-items: center;
            }

            .btn-primary {
                padding: 7.5px 20px;
            }

            .btn-secondary {
                width: 100%;
                padding: 7.5px 20px;
                margin-top: 20px;
            }
        }

        .home-img {
            display: none;
        }
    }
}

@media (max-width: 480px) {
    .home {
        background-position-y: unset;
        background-position-x: 60%;

        .home-leftSide {
            .top-title {
                width: 75%;
            }

            .home-logo {
                width: 135px;
            }

            .home-title {
                margin-bottom: 15px;
            }

            .btn-primary,
            .btn-secondary {
                font-size: 1rem;
            }
        }
    }
}

/* patient page */
.patient-home {
    justify-content: space-between;
    padding: 17.5vh 7.5vw 12.5vh 7.5vw;
}
.patient-home-leftSide {
    display: flex;
    flex-direction: column;
}
.patient-home-leftSide svg {
    width: 25vw;
}
.patient-home-title {
    color: #333333;
    font-weight: 600;
    margin-top: 2.5vh;
    margin-bottom: 20px;
}
.patient-home-subtitle {
    margin-bottom: 20px;
    color: #333333;
}
.home-btn-section {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.home-btn-section .patient-home-btn {
    width: fit-content;
    padding: 15px 35px;
    background: #00c8f0b3;
    color: #fff;
    border-radius: 100px;
    font-size: 1.2rem;
    font-weight: 600;
    filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.25));
    -webkit-appearance: none !important;
    transition: all 0.3s ease;
    margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-btn-section .patient-home-btn:hover {
    background: #00c8f0;
}
.home-btn-section p {
    margin: 5px 0;
}
.patient-home .home-img {
    will-change: transform;
    margin-top: -3vh;
}
.home-img-mobile {
    display: none;
}

@media (max-width: 1024px) {
    .patient-home {
        width: 100vw;
        padding: 5vh 0;
        padding-bottom: 0;
    }
    .patient-home-leftSide {
        padding: 5vh 7.5vw;
        align-items: center;
    }
    .patient-home-leftSide svg {
        width: 55vw;
    }
    .patient-home-title {
        margin-top: 15px;
        margin-bottom: 25px;
        text-align: center;
    }
    .patient-home-subtitle {
        text-align: center;
        margin-bottom: 20px;
    }
    .patient-home .home-img {
        display: none;
    }
    .home-img-mobile {
        display: unset;
        margin-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .patient-home-title {
        margin-bottom: 10px;
    }
    .patient-home-subtitle {
        margin-bottom: 15px;
    }
    .home-btn-section {
        flex-direction: column;
    }
    .home-btn-section .patient-home-btn {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .home-btn-section p {
        text-align: center;
        white-space: nowrap;
    }
}
