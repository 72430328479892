.termsBg {
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 5vh 5vw;

    h2 {
        font-weight: bold;
        font-size: 28px;
        line-height: 2;
        margin: 0;
    }

    p,
    span,
    strong,
    a,
    a li,
    li {
        font-size: 16px;
        line-height: 1.6;
    }

    span {
        font-weight: 600;
        margin-bottom: 1em;
    }

    a {
        text-decoration: underline !important;
        color: blue !important;
    }

    h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 1.8;
    }

    h4 {
        font-weight: bold;
        font-size: 18px;
        line-height: 1.6;
    }

    p ul {
        padding: 0 3vw;
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.5);
        margin: 15px 0 25px 0;
    }
}
