#smile-care {
  padding: 7.5vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eff6f8;
}
#smile-care h2 {
  margin-bottom: 0;
}
#smile-care .subtitle {
  color: #a5a5a5;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 40px;
  font-weight: normal;
}

@media (max-width: 480px) {
  #smile-care .subtitle {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
}
